import React, { useState } from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import AccountsBenefitModal from 'components/account-benefits-modal'
import { useStyles } from './styles'
import PaymentInformation from '../components/payment-information'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { validationSchema } from '../utils'
import { withStripeElements } from 'lib/stripe'
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { gtmEvent } from 'lib/gtm'
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/client'
import { PurchaseLessonPackQuery, REDEEM_LESSON_CREDIT_QUERY } from './query'
import CircularProgress from '@material-ui/core/CircularProgress'
import SelectLessonPack from '../components/SelectLessonPack'
import themes, { extendTheme } from 'themes'
import ProgressIndicator from 'components/progress-indicators'
import BackButton from 'components/back-button'
import { ThemeProvider } from '@material-ui/core'
import InitialsAvatar from 'components/avatars/initials-avatar'
import Typography from '@material-ui/core/Typography'
import StepDescriptionOfferings from '../components/step-description-offerings'
import AlertWithDivider from '../components/AlertWithDivider'
import CoachLessonHeader from '../components/CoachLessonHeader'

const LessonPackReviewLayout = ({
  previousUrl,
  lessonInfo,
  locationId,
  startDateTime,
  student,
  isComplete,
  setIsComplete,
  setLastDigits,
  setCardBrand,
  setSelectedLessonPackId,
  selectedLessonPackId,
  lessonPackQuantity,
  setLessonPackQuantity,
  eventObject = {},
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [
    purchaseLessonPack,
    { loading: purchaseLessonPackQueryLoading },
  ] = useMutation(PurchaseLessonPackQuery)

  const [
    redeemLessonCredit,
    { loading: redeemLessonCreditLoading },
  ] = useMutation(REDEEM_LESSON_CREDIT_QUERY, {
    onCompleted: data => {
      const { success, message } = data.redeemLessonCredit
      if (success) {
        enqueueSnackbar(message, {
          variant: 'success',
        })
      } else {
        enqueueSnackbar(message, {
          variant: 'error',
        })
      }
    },
  })
  const { coach, facilities } = lessonInfo
  const facility = facilities.find(({ id }) => id === locationId)
  const studentName = `${student.firstName} ${student.lastName}`

  const form = useForm({
    resolver: yupResolver(validationSchema(true)),
  })
  const stripe = useStripe()
  const elements = useElements()

  const onSubmit = () => async formValues => {
    const { error: stripeError, token: stripeToken } = await stripe.createToken(
      elements.getElement(CardNumberElement),
      {
        name: formValues.payment.name,
        address_country: 'US',
      },
    )

    if (stripeError) {
      enqueueSnackbar(stripeError.message, {
        variant: 'error',
      })
      return Promise.resolve()
    }
    return purchaseLessonPack({
      variables: {
        lessonPackId: selectedLessonPackId,
        stripeToken: stripeToken?.id,
      },
    })
      .then(({ data }) => {
        const { success, message } = data.purchaseLessonPack

        if (success) {
          enqueueSnackbar(message, {
            variant: 'success',
          })
          gtmEvent(eventObject)
          // Remove the below event in the future.
          gtmEvent({
            event: 'formSubmit',
            formCategory: 'purchase-lesson-pack',
            formAction: 'clicked-complete-purchase-button',
          })
          redeemLessonCredit({
            variables: {
              studentId: student.id,
              lessonTypeId: lessonInfo.id,
              startDateTime,
              locationId: facility.id,
            },
          }).then(({ data }) => {
            const { success } = data.redeemLessonCredit
            if (success) {
              setIsComplete(!isComplete)
              setLastDigits(stripeToken.card.last4)
              setCardBrand(stripeToken.card.brand)
            }
          })
        } else {
          enqueueSnackbar(message, {
            variant: 'error',
          })
        }
      })
      .catch(error => {
        window.rg4js &&
          window.rg4js('send', {
            error: error,
            tags: ['lesson-pack', 'form-submit'],
          })
        enqueueSnackbar('An error has occurred. Please try again later.', {
          variant: 'error',
        })
      })
  }

  if (purchaseLessonPackQueryLoading || redeemLessonCreditLoading) {
    return (
      <Container className={classes.root}>
        <div className={classes.loadingStyles}>
          <CircularProgress color="primary" />
        </div>
      </Container>
    )
  }

  return (
    <Container className={classes.rootRefresh}>
      <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
        <Grid container>
          <ProgressIndicator value={75} />
          <BackButton previousUrl={previousUrl} />
          <CoachLessonHeader lessonType={lessonInfo} coach={coach} />
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid container>
            <Grid item md={7} xs={12} className={classes.gridItem}>
              <StepDescriptionOfferings
                stepText="Step 3 of 3"
                title="Review"
                description="Please review the information below."
              />
              <Grid
                container
                item
                xs={12}
                md={5}
                className={classes.studentCard}
              >
                <InitialsAvatar
                  name={`${student.firstName} ${student.lastName}`}
                  classes={classes}
                />
                <div className={classes.infoWrapped}>
                  <Typography
                    variant="caption"
                    className={`${classes.fontBolded} ${classes.captionText}`}
                    data-testid="student-name"
                  >
                    {studentName}
                  </Typography>
                  <Typography
                    variant="caption"
                    className={`${classes.captionText} ${classes.lighterText}`}
                  >
                    Student
                  </Typography>
                </div>
              </Grid>
              <Divider className={classes.divider} />
              <Grid item sm={10} xs={12}>
                <SelectLessonPack
                  lessonInfo={lessonInfo}
                  selectedLessonPackId={selectedLessonPackId}
                  setSelectedLessonPackId={setSelectedLessonPackId}
                  lessonPackQuantity={lessonPackQuantity}
                  setLessonPackQuantity={setLessonPackQuantity}
                />
              </Grid>
              <FormProvider {...form}>
                <form>
                  <Grid item sm={10} xs={12}>
                    <Box mb={4}>
                      <PaymentInformation
                        lessonType={lessonInfo}
                        paymentRequired={true}
                      />
                    </Box>
                    <AlertWithDivider text="Your credit card information is not being stored in our system." />
                  </Grid>
                  <Grid item md={5}>
                    <Box mt={6} mb={10}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={form.handleSubmit(onSubmit())}
                        disabled={
                          !selectedLessonPackId || form.formState.isSubmitting
                        }
                        data-testid="complete-booking-button"
                      >
                        Complete Purchase
                      </Button>
                    </Box>
                  </Grid>
                </form>
              </FormProvider>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
      <AccountsBenefitModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </Container>
  )
}

export default withStripeElements(LessonPackReviewLayout)
