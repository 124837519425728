import styles from './styles'

const CoachInsightsCreateCard = ({ icon, title, description }) => {
  const classes = styles()
  return (
    <div
      className={classes.root}
    >
      <div>
        <img src={icon} alt='map marker event' />
      </div>
      <p className={classes.title}>{title}</p>
      <p className={classes.description}>{description}</p>
    </div>
  )
}

export default CoachInsightsCreateCard
