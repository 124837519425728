import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  exportButton: {
    marginRight: '0',
    borderColor: '#00234B80',
    color: '#043362',
  },
  pageHeader: {
    borderBottom: '0 !important',
  },
  tabContainer: {
    maxWidth: '100%',
    padding: '0 60px',
    borderBottom: '1px solid #e0e0e0',
    [theme.breakpoints.down('sm')]: {
      padding: '0 24px',
    },
  },
}))

export default useStyles
