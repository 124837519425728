import React from 'react'
import { Typography, TextField, Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { formatCurrencyInput, formatCurrencyOnBlur } from 'utils/number'

const CoachingGoals = () => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  const formValues = watch()

  const handleCurrencyChange = (name) => (event) => {
    const formattedValue = formatCurrencyInput(event.target.value)
    setValue(name, formattedValue, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const handleBlur = (name) => (event) => {
    if (event.target.value) {
      const formattedValue = formatCurrencyOnBlur(event.target.value)
      setValue(name, formattedValue, {
        shouldValidate: true,
        shouldDirty: true,
      })
    }
  }

  return (
    <div>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontSize: '34px', lineHeight: '42px', fontWeight: 500 }}
      >
        Coaching Goals
      </Typography>

      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ mb: 3, fontSize: '16px', lineHeight: '24px', color: '#0000008A' }}
      >
        Enter your coaching goals to establish clear benchmarks for evaluating your performance.
      </Typography>

      <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <TextField
          {...register('avgMonthlyRevenue')}
          label="Average Monthly Revenue"
          value={formValues.avgMonthlyRevenue || ''}
          onChange={handleCurrencyChange('avgMonthlyRevenue')}
          onBlur={handleBlur('avgMonthlyRevenue')}
          InputProps={{
            startAdornment: <span>$</span>,
          }}
          error={!!errors.avgMonthlyRevenue}
          helperText={errors.avgMonthlyRevenue?.message}
          fullWidth
        />
        <TextField
          {...register('avgLeads')}
          label="Average Leads"
          value={formValues.avgLeads || ''}
          onChange={(e) => setValue('avgLeads', e.target.value)}
          error={!!errors.avgLeads}
          helperText={errors.avgLeads?.message}
          fullWidth
        />
        <TextField
          {...register('avgCoachingHours')}
          label="Average Coaching Hours"
          value={formValues.avgCoachingHours || ''}
          onChange={(e) => setValue('avgCoachingHours', e.target.value)}
          error={!!errors.avgCoachingHours}
          helperText={errors.avgCoachingHours?.message}
          fullWidth
        />
      </Box>
    </div>
  )
}

export default CoachingGoals
