import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  footer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    backgroundColor: '#F7F7F7',
    padding: '.25rem 1rem',
    borderRadius: '4px',
  },
  footerIcon: {
    color: '#6A6A6A',
    paddingRight: '.5rem',
    borderRight: '1px solid #9E9E9E',
  },
  footerText: {
    fontSize: '12px',
    fontFamily: 'Roboto',
    lineHeight: '16px',
    fontWeight: 400,
    color: '#000000DE',
    '& a': {
      color: '#000000DE',
      fontWeight: 500,
    },
  },
}))

export default useStyles
