import gql from 'graphql-tag'

export const GetReportsQuery = gql`
  query Reports {
    reports {
      coachId
      createdAt
      facilityId
      id
      name
      updatedAt
      year
    }
  }
`

export const GetFacilityConstantsQuery = gql`
  query FacilityConstants {
    facilityConstants {
      avgDrivingRangeSpendInCents
      avgFbSpendInCents
      avgMerchandiseSpendInCents
      avgOtherSpendInCents
      coachId
      facilityId
      id
      membershipCostInCents
      reportId
    }
  }
`

export const GetFacilityConstantsByReportIdQuery = gql`
  query FacilityValue($reportId: ID!) {
    facilityValue(reportId: $reportId) {
      hoursWithProfessional
      newStudents
      participantsTurnedMembers
      totalRevenueToClub
      totalStudents
      additionalRevenueToFacility {
        agreedModel
        bvgiModel
        month
        pgaSuggestedModel
      }
      directPaymentToFacilityInCents {
        month
        value
      }
    }
  }
`

export const GetBusinessInsightsByReportIdQuery = gql`
  query BusinessInsights($reportId: ID!) {
    businessInsights(reportId: $reportId) {
      contactConversion
      contactValueInCents
      currentContacts
      effectiveHourlyRate
      numberOfStudents
      percentageTimeCoaching
      potentialContactValueInCents
      totalNewContactsNeeded
      ytdNewStudents
      ytdProfitInCents
      ytdRevenueInCents
      monthlyFinancials {
        month
        otherExpensesInCents
        purchasesFromClubInCents
        rentPaidToClubInCents
        revenueInCents
      }
    }
  }
`
