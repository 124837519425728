import React, { useState, useReducer } from 'react'
import {
  Paper,
  Box,
} from '@mui/material'
import { Button, Stepper, Step, StepLabel, Hidden } from '@material-ui/core'
import useStyles from './styles'
import GeneralInformation from './general-information'
import FacilityMetrics from './facility-metrics'
import MonthlyInputs from './monthly-inputs'
import Confirmation from './confirmation'
import { GetFacilityConstantsQuery, CreateReportMutation, UpsertFacilityConstantMutation, UpsertMonthlyInputMutation, UpsertBusinessGoalMutation } from './query'
import { useQuery, useMutation } from '@apollo/client'
import PageHeader from 'pages/pga-coach/students/student-show-page/header-components/page-header'
import { FormProvider, useForm } from 'react-hook-form'
import { convertToCents } from 'utils/number'
import CoachingGoals from './coaching-goals'

const STEP_GENERAL_INFORMATION = 'General Information'
const STEP_FACILITY_METRICS = 'Facility Metrics'
const STEP_MONTHLY_INPUTS = 'Monthly Inputs'
const STEP_CONFIRMATION = 'Confirmation'
const STEP_COACHING_GOALS = 'Coaching Goals'

const steps = [
  STEP_GENERAL_INFORMATION,
  STEP_COACHING_GOALS,
  STEP_FACILITY_METRICS,
  STEP_MONTHLY_INPUTS,
  STEP_CONFIRMATION,
]

const MONTHS = [
  { value: 'Jan', label: 'Jan' },
  { value: 'Feb', label: 'Feb' },
  { value: 'Mar', label: 'Mar' },
  { value: 'Apr', label: 'Apr' },
  { value: 'May', label: 'May' },
  { value: 'Jun', label: 'Jun' },
  { value: 'Jul', label: 'Jul' },
  { value: 'Aug', label: 'Aug' },
  { value: 'Sept', label: 'Sept' },
  { value: 'Oct', label: 'Oct' },
  { value: 'Nov', label: 'Nov' },
  { value: 'Dec', label: 'Dec' },
]

const initialState = {
  reportName: '',
  year: '2024',
  facility: '',
  avgMonthlyRevenue: '',
  avgLeads: '',
  avgCoachingHours: '',
  facilityMetrics: {},
  monthlyInputs: {
    Jan: {},
    Feb: {},
    Mar: {},
    Apr: {},
    May: {},
    Jun: {},
    Jul: {},
    Aug: {},
    Sept: {},
    Oct: {},
    Nov: {},
    Dec: {},
  },
  costOfMembership: null,
  avgFoodAndBevSpend: null,
  avgMerchSpend: null,
  avgDrivingRangeSpend: null,
  avgOtherSpend: null,
}

const formReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.field]: action.value,
      }
    case 'UPDATE_MONTHLY_INPUT':
      return {
        ...state,
        monthlyInputs: {
          ...state.monthlyInputs,
          [action.month]: {
            ...state.monthlyInputs[action.month],
            [action.field]: action.value,
          },
        },
      }
    default:
      return state
  }
}

const CreateReport = () => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [errors, setErrors] = useState({})
  const [report, setReport] = useState(null)
  const [facilityConstant, setFacilityConstant] = useState(null)
  const { loading: facilityConstantsLoading, data: facilityConstantsData } = useQuery(GetFacilityConstantsQuery)
  const [createReport] = useMutation(CreateReportMutation)
  const [upsertFacilityConstant] = useMutation(UpsertFacilityConstantMutation)
  const [upsertMonthlyInput] = useMutation(UpsertMonthlyInputMutation)
  const [upsertBusinessGoal] = useMutation(UpsertBusinessGoalMutation)
  const [formData, dispatch] = useReducer(formReducer, initialState)

  const methods = useForm({
    defaultValues: {
      monthlyInputs: {
        Jan: { saved: false },
        Feb: { saved: false },
        Mar: { saved: false },
        Apr: { saved: false },
        May: { saved: false },
        Jun: { saved: false },
        Jul: { saved: false },
        Aug: { saved: false },
        Sept: { saved: false },
        Oct: { saved: false },
        Nov: { saved: false },
        Dec: { saved: false },
      },
      costOfMembership: '',
      avgFoodAndBevSpend: '',
      avgMerchSpend: '',
      avgDrivingRangeSpend: '',
      avgOtherSpend: '',
      avgCoachingHours: '',
      avgLeads: '',
    },
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    dispatch({ type: 'UPDATE_FIELD', field: name, value })
  }

  const handleCreateReport = async ({ name, year, facilityId }) => {
    try {
      const { data, errors } = await createReport({
        variables: { input: { name, year, facilityId } },
      })

      if (errors) {
        throw new Error(errors[0].message)
      }

      if (data?.createReport?.report) {
        setReport(data.createReport.report)
      } else {
        throw new Error('Failed to create report')
      }
    } catch (error) {
      throw new Error(error.message)
    }
  }

  const handleUpsertFacilityConstant = async ({ facilityId, reportId, input }) => {
    try {
      const { data, errors } = await upsertFacilityConstant({
        variables: { facilityId, reportId, input },
      })

      if (errors) {
        throw new Error(errors[0].message)
      }

      if (data?.upsertFacilityConstant?.facilityConstant) {
        setFacilityConstant(data.upsertFacilityConstant.facilityConstant)
      } else {
        throw new Error('Failed to update facility constant')
      }
    } catch (error) {
      throw new Error(error.message)
    }
  }

  const handleUpsertMonthlyInput = async ({ facilityId, reportId, month, input }) => {
    try {
      const { data, errors } = await upsertMonthlyInput({
        variables: { facilityId, reportId, month, input },
      })

      if (errors) {
        throw new Error(errors[0].message)
      }

      if (!data?.upsertMonthlyMetric) {
        throw new Error('Failed to update monthly input')
      }
    } catch (error) {
      throw new Error(error.message)
    }
  }

  const handleUpsertBusinessGoal = async ({ reportId, input }) => {
    try {
      const { data, errors } = await upsertBusinessGoal({ variables: { reportId, input } })

      if (errors) {
        throw new Error(errors[0].message)
      }

      if (!data?.upsertBusinessGoal) {
        throw new Error('Failed to update coaching goals')
      }
    } catch (error) {
      throw new Error(error.message)
    }
  }

  const handleMonthlyInputChange = (month, field, value) => {
    dispatch({ type: 'UPDATE_MONTHLY_INPUT', month, field, value })
  }

  const validateGeneralInformation = () => {
    const newErrors = {}

    if (!formData.reportName.trim()) {
      newErrors.reportName = 'Report name is required'
    }

    if (!formData.year) {
      newErrors.year = 'Year is required'
    }

    if (!formData.facility) {
      newErrors.facility = 'Facility is required'
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleNext = async () => {
    if (activeStep === 0) {
      const isValid = validateGeneralInformation()
      if (!isValid) return
      await handleCreateReport({
        name: formData.reportName,
        year: parseInt(formData.year),
        facilityId: formData.facility,
      })
    } else if (activeStep === 1) {
      const formValues = methods.getValues()

      await handleUpsertBusinessGoal({
        reportId: report.id,
        input: {
          hoursPerMonth: formValues.avgCoachingHours ? parseInt(formValues.avgCoachingHours) : null,
          leadsPerMonth: formValues.avgLeads ? parseInt(formValues.avgLeads) : null,
          revenuePerYearInCents: formValues.avgMonthlyRevenue
            ? parseInt(parseFloat(formValues.avgMonthlyRevenue.replace(/[$,]/g, '')) * 100) * 12 : null,
          workingMonths: 12,
        },
      })
    } else if (activeStep === 2) {
      const formValues = methods.getValues()

      await handleUpsertFacilityConstant({
        facilityId: formData.facility,
        reportId: report.id,
        input: {
          membershipCostInCents: convertToCents(formValues.costOfMembership),
          avgFbSpendInCents: convertToCents(formValues.avgFoodAndBevSpend),
          avgMerchandiseSpendInCents: convertToCents(formValues.avgMerchSpend),
          avgDrivingRangeSpendInCents: convertToCents(formValues.avgDrivingRangeSpend),
          avgOtherSpendInCents: convertToCents(formValues.avgOtherSpend),
        },
      })
    } else if (activeStep === 3) {
      const formValues = methods.getValues()

      await Promise.all(
        Object.entries(formValues.monthlyInputs).map(async ([month, data]) => {
          if (Object.keys(data).length > 0) {
            const monthIndex = MONTHS.findIndex((m) => m.value === month) + 1
            await handleUpsertMonthlyInput({
              facilityId: formData.facility,
              reportId: report.id,
              month: monthIndex,
              input: {
                coachingHours: parseInt(data.coachingHours) || 0,
                adminHours: parseInt(data.adminHours) || 0,
                numberOfLeads: parseInt(data.numberOfLeads) || 0,
                directPaymentToFacilityInCents: convertToCents(data.directPaymentToFacilityInCents),
                purchasesFromFacilityInCents: convertToCents(data.purchasesFromFacilityInCents),
                otherExpensesInCents: convertToCents(data.otherExpensesInCents),
                revenueInCents: convertToCents(data.revenueInCents),
                studentsConvertedToMembers: parseInt(data.studentsConvertedToMembers) || 0,
                numberOfNewStudents: parseInt(data.numberOfNewStudents) || 0,
                numberOfStudentsThatTookLessons: parseInt(data.numberOfStudentsThatTookLessons) || 0,
                totalNumberOfStudents: (parseInt(data.numberOfNewStudents) || 0) + (parseInt(data.numberOfStudentsThatTookLessons) || 0),
                marketingSpendInCents: convertToCents(data.marketingSpendInCents),
                numberOfContacts: (parseInt(data.numberOfLeads) || 0) + (parseInt(data.numberOfNewStudents) || 0) + (parseInt(data.numberOfStudentsThatTookLessons) || 0),
              },
            })
          }
        }),
      )
    }
    setActiveStep((prevStep) => prevStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <GeneralInformation
            formData={formData}
            handleChange={handleChange}
            errors={errors}
            facilityConstantsLoading={facilityConstantsLoading}
            facilityConstantsData={facilityConstantsData}
          />
        )
      case 1:
        return (
          <CoachingGoals
            formData={formData}
            handleChange={handleChange}
            errors={errors}
          />
        )
      case 2:
        return (
          <FacilityMetrics
            formData={formData}
            handleChange={handleChange}
            report={report}
          />
        )
      case 3:
        return (
          <MonthlyInputs
            formData={formData}
            handleMonthlyInputChange={handleMonthlyInputChange}
            facilityConstant={facilityConstant}
          />
        )
      case 4:
        return (
          <Confirmation report={report} />
        )
      default:
        return null
    }
  }

  const getLabelActionButton = (currentStep) => {
    switch (currentStep) {
      case 2:
        return 'Save'
      default:
        return 'Next'
    }
  }

  return (
    <div>
      <Hidden mdUp>
        <PageHeader
          title="Create Report"
          crumbs={[{ label: 'Reports', to: '/pga-coach/reports' }]}
        />
      </Hidden>
      <div className={classes.createReportContainer}>
        <Hidden smDown>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconProps={{ icon: '' }}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Hidden>

        <FormProvider {...methods}>
          <Paper
            elevation={1}
            sx={{
              marginTop: '6rem',
              p: 6,
              maxWidth: 600,
              mx: 'auto',
              borderRadius: '12px',
              border: '1px solid #E0E0E0',
              gap: '10px',
              '@media (max-width: 960px)': {
                marginTop: '0',
                p: 3,
                borderRadius: '0',
                border: 'none',
                boxShadow: 'none',
              },
            }}
          >
            {renderStepContent()}
            <Hidden smDown>
              {
                activeStep < steps.length - 1 && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, gap: 2 }}>
                    {activeStep > 0 && activeStep < steps.length - 1 && (
                      <Button onClick={handleBack} variant="outlined">
                        Back
                      </Button>
                    )}
                    <Button
                      onClick={handleNext}
                      variant="containedPrimary"
                      sx={{ ml: activeStep === 0 ? 'auto' : 0 }}
                    >
                      {getLabelActionButton(activeStep)}
                    </Button>
                  </Box>
                )
              }
            </Hidden>
            <Hidden mdUp>
              {
                activeStep < steps.length - 1 && (
                  <div className={classes.mobileFooter}>
                    {activeStep > 0 && activeStep < steps.length - 1 && (
                      <Button onClick={handleBack} variant="outlined">
                        Back
                      </Button>
                    )}
                    <Button
                      onClick={handleNext}
                      variant="containedPrimary"
                      sx={{ ml: activeStep === 0 ? 'auto' : 0 }}
                    >
                      {getLabelActionButton(activeStep)}
                    </Button>
                  </div>
                )
              }
            </Hidden>
          </Paper>
        </FormProvider>
      </div>
    </div>
  )
}

export default CreateReport
