import { useState } from 'react'
import { Box, Typography, Paper, Grid, TextField, ToggleButton, ToggleButtonGroup, Tabs, Tab } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import { Button } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import {
  mobileTabsContainerStyles,
  tabsStyles,
  monthSelectionPaperStyles,
  toggleButtonGroupStyles,
  nextMonthPaperStyles,
  nextMonthIconContainerStyles,
  nextMonthIconStyles,
  nextMonthTextStyles,
  introTextStyles,
  checkIconStyles,
} from './styles'
import { formatCurrencyInput, formatCurrencyOnBlur } from 'utils/number'

const MONTHS = [
  { value: 'Jan', label: 'Jan' },
  { value: 'Feb', label: 'Feb' },
  { value: 'Mar', label: 'Mar' },
  { value: 'Apr', label: 'Apr' },
  { value: 'May', label: 'May' },
  { value: 'Jun', label: 'Jun' },
  { value: 'Jul', label: 'Jul' },
  { value: 'Aug', label: 'Aug' },
  { value: 'Sept', label: 'Sept' },
  { value: 'Oct', label: 'Oct' },
  { value: 'Nov', label: 'Nov' },
  { value: 'Dec', label: 'Dec' },
]

const MonthlyInputs = () => {
  const [selectedMonth, setSelectedMonth] = useState('Jan')
  const [savedMonths, setSavedMonths] = useState(new Set())
  const { register, setValue, watch, formState: { errors } } = useFormContext()

  const monthlyInputs = watch('monthlyInputs')
  const currentMonthData = monthlyInputs?.[selectedMonth] || {}

  const handleMonthChange = (event, newMonth) => {
    if (newMonth !== null) {
      setSelectedMonth(newMonth)
    }
  }

  const handleCurrencyInputChange = (fieldName) => (event) => {
    const formattedValue = formatCurrencyInput(event.target.value)
    setValue(`monthlyInputs.${selectedMonth}.${fieldName}`, formattedValue, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const handleInputChange = (fieldName) => (event) => {
    setValue(`monthlyInputs.${selectedMonth}.${fieldName}`, event.target.value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const handleBlur = (fieldName) => (event) => {
    if (event.target.value) {
      const formattedValue = formatCurrencyOnBlur(event.target.value)
      setValue(`monthlyInputs.${selectedMonth}.${fieldName}`, formattedValue, {
        shouldValidate: true,
        shouldDirty: true,
      })
    }
  }

  const handleNextMonth = () => {
    setSavedMonths(prev => new Set([...prev, selectedMonth]))

    const nextMonthIndex = MONTHS.findIndex((month) => month.value === selectedMonth) + 1
    if (nextMonthIndex < MONTHS.length) {
      setSelectedMonth(MONTHS[nextMonthIndex].value)
    }
  }

  const handleTabChange = (event, newValue) => {
    setSelectedMonth(newValue)
  }

  const registerCurrencyField = (fieldName, validationRules = {}) => ({
    ...register(`monthlyInputs.${selectedMonth}.${fieldName}`, validationRules),
    value: currentMonthData[fieldName] || '',
    onChange: handleCurrencyInputChange(fieldName),
    onBlur: handleBlur(fieldName),
    error: !!errors?.monthlyInputs?.[selectedMonth]?.[fieldName],
    helperText: errors?.monthlyInputs?.[selectedMonth]?.[fieldName]?.message,
  })

  const registerInputField = (fieldName, validationRules = {}) => ({
    ...register(`monthlyInputs.${selectedMonth}.${fieldName}`, validationRules),
    value: currentMonthData[fieldName] || '',
    onChange: handleInputChange(fieldName),
    error: !!errors?.monthlyInputs?.[selectedMonth]?.[fieldName],
    helperText: errors?.monthlyInputs?.[selectedMonth]?.[fieldName]?.message,
  })

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Monthly Inputs
      </Typography>

      <Typography variant="body1" sx={introTextStyles}>
        Begin by selecting a month from the list below and inputting your monthly metrics.
        Use the month tabs to add details.
      </Typography>

      <Box sx={mobileTabsContainerStyles}>
        <Tabs
          value={selectedMonth}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="month selection"
          sx={tabsStyles}
        >
          {MONTHS.map((month) => (
            <Tab
              key={month.value}
              value={month.value}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {savedMonths.has(month.value) && (
                    <CheckIcon sx={{ mr: 0.5, fontSize: '1rem' }} />
                  )}
                  {month.label}
                </Box>
              }
            />
          ))}
        </Tabs>
      </Box>

      <Paper sx={monthSelectionPaperStyles}>
        <Box>
          <ToggleButtonGroup
            value={selectedMonth}
            exclusive
            onChange={handleMonthChange}
            aria-label="month selection"
            sx={toggleButtonGroupStyles}
          >
            {MONTHS.map((month) => (
              <ToggleButton key={month.value} value={month.value}>
                {savedMonths.has(month.value) && (
                  <CheckIcon sx={checkIconStyles} />
                )}
                {month.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
      </Paper>

      <Typography variant="h5" gutterBottom>
        Coaching Metrics
      </Typography>
      <Typography variant="body2" sx={{ mb: 3 }}>
        Enter your monthly coaching metrics for the selected month.
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            {...registerCurrencyField('revenueInCents')}
            fullWidth
            label="Total Coaching Revenue"
            InputProps={{
              startAdornment: <span>$</span>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...registerInputField('coachingHours')}
            fullWidth
            label="Coaching Hours"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...registerInputField('adminHours')}
            fullWidth
            label="Administrative Hours"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...registerCurrencyField('purchasesFromFacilityInCents')}
            fullWidth
            label="Purchases to Club"
            helperText="Supplies, green fees, etc."
            InputProps={{
              startAdornment: <span>$</span>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...registerCurrencyField('directPaymentToFacilityInCents')}
            fullWidth
            label="Rent Paid to Club"
            InputProps={{
              startAdornment: <span>$</span>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...registerCurrencyField('marketingSpendInCents')}
            fullWidth
            label="Marketing Spend"
            InputProps={{
              startAdornment: <span>$</span>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...registerCurrencyField('otherExpensesInCents')}
            fullWidth
            label="Other Expenses"
            InputProps={{
              startAdornment: <span>$</span>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...registerInputField('numberOfStudentsThatTookLessons')}
            fullWidth
            label="Unique Students"
            variant="outlined"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...registerInputField('numberOfNewStudents')}
            fullWidth
            label="New Students"
            variant="outlined"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...registerInputField('numberOfLeads')}
            fullWidth
            label="New Leads"
            variant="outlined"
            helperText="Number of new leads"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...registerInputField('studentsConvertedToMembers')}
            fullWidth
            label="Number of Conversions"
            variant="outlined"
            helperText="Students converted to members"
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <Paper sx={nextMonthPaperStyles}>
            <Box sx={nextMonthIconContainerStyles}>
              <EventAvailableIcon sx={nextMonthIconStyles} />
              <Typography variant="body2" sx={nextMonthTextStyles}>
                Add next month's metrics
              </Typography>
            </Box>
            <Button
              onClick={handleNextMonth}
              variant="outlined"
              disabled={!currentMonthData.revenueInCents}
            >
              GO TO NEXT MONTH
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default MonthlyInputs
