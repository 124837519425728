import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    marginTop: '8px',
    fontWeight: 600,
  },
  iconContainer: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: '#00234B14',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '22px',
  },
  icon: {
    fontSize: '40px !important',
    color: '#043362',
  },
  description: {
    marginTop: '32px',
    marginBottom: '32px',
    lineHeight: '24px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
}))

export default useStyles
