import {
  Grid,
  useMediaQuery,
  useTheme,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core'
import useStyles from './styles'
import CoachInsightsStatsCard from 'components/coach-insights-stats-card'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { formatMoneyFromCents } from 'lib/utils/number'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined'
import CoachInsightsProfitChart from 'components/coach-insights-profit'
import { useState, useEffect, useCallback } from 'react'
import mapMarkerEvent from 'images/event-search/map-marker-event.svg'
import CoachInsightsCreateCard from 'components/coach-insights-create-card'
import CoachInsightsFacilityChart from 'components/coach-insights-facility-chart'
import InfoTextContainer from 'components/info-text-container'
import { Skeleton } from '@material-ui/lab'
import EmptyPage from 'components/empty-page-state'
import ShowChartIcon from '@mui/icons-material/ShowChart'

const MyFacilityReport = ({ facilityConstants, facilityConstantsLoading }) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const [
    additionalRevenueToFacility,
    setAdditionalRevenueToFacility,
  ] = useState([])

  const currentYear = new Date().getFullYear()
  const [selectedYear, setSelectedYear] = useState(currentYear.toString())

  const handleYearChange = event => {
    setSelectedYear(event.target.value)
  }

  const getTotalRevenueToClub = useCallback(() => {
    const modelTotals =
      additionalRevenueToFacility?.reduce((acc, item) => {
        acc.pgaSuggested = (acc.pgaSuggested || 0) + item.pgaSuggestedModel
        acc.bvgi = (acc.bvgi || 0) + item.bvgiModel
        acc.agreed = (acc.agreed || 0) + item.agreedModel
        return acc
      }, {}) || {}

    return `$${Math.min(...Object.values(modelTotals)).toFixed(
      2,
    )} - $${Math.max(...Object.values(modelTotals)).toFixed(2)}`
  }, [additionalRevenueToFacility])

  const renderSkeletonCard = () => (
    <div data-testid="skeleton-card" className={classes.skeletonCard}>
      <Skeleton variant="circle" width={40} height={40} />
      <Skeleton variant="text" width="60%" height={40} />
      <Skeleton variant="text" width="40%" />
    </div>
  )

  useEffect(() => {
    if (facilityConstants?.additionalRevenueToFacility) {
      setAdditionalRevenueToFacility(
        Object.values(facilityConstants.additionalRevenueToFacility).map(
          item => ({
            ...item,
            bvgiModel: item.bvgiModel / 100,
            agreedModel: item.agreedModel / 100,
            pgaSuggestedModel: item.pgaSuggestedModel / 100,
          }),
        ),
      )
    } else {
      setAdditionalRevenueToFacility([])
    }
  }, [facilityConstants])

  return (
    <div>
      <div className={classes.gridContainer}>
        <div className={classes.yearSelector}>
          <div></div>
          <FormControl className={classes.select}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedYear}
              onChange={handleYearChange}
            >
              <MenuItem value="2024">2024</MenuItem>
              <MenuItem value="2023">2023</MenuItem>
              <MenuItem value="2022">2022</MenuItem>
            </Select>
          </FormControl>
        </div>
        {// TODO: Add link to learn more about models
        isMobile && (
          <InfoTextContainer>
            <span>
              This is the information about what this specific model means and
              how it was calculated.{' '}
              <u>
                <b>Learn about models</b>
              </u>
            </span>
          </InfoTextContainer>
        )}
        {facilityConstantsLoading && (
          <Grid container spacing={isMobile ? 2 : 3}>
            {[...Array(6)].map((_, index) => (
              <Grid item xs={6} sm={4} key={index}>
                {renderSkeletonCard()}
              </Grid>
            ))}
            <Grid item sm={12} xs={12}>
              <Skeleton variant="rect" height={300} />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Skeleton variant="rect" height={300} />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Skeleton variant="rect" height={300} />
            </Grid>
          </Grid>
        )}
        {!facilityConstantsLoading && facilityConstants ? (
          <Grid container spacing={isMobile ? 2 : 3}>
            <Grid item xs={12} sm={4}>
              <CoachInsightsStatsCard
                icon={AttachMoneyIcon}
                value={`$${additionalRevenueToFacility
                  ?.reduce(
                    (total, payment) => total + payment.pgaSuggestedModel,
                    0,
                  )
                  .toFixed(2)}`}
                description="PGA Suggested Model"
                avatarBackground="#043362"
                iconColor="#FFF"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CoachInsightsStatsCard
                icon={AttachMoneyIcon}
                value={`$${additionalRevenueToFacility
                  ?.reduce((total, payment) => total + payment.bvgiModel, 0)
                  .toFixed(2)}`}
                description="BVGI Model"
                avatarBackground="#3268A7"
                iconColor="#FFF"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CoachInsightsStatsCard
                icon={AttachMoneyIcon}
                value={`$${additionalRevenueToFacility
                  ?.reduce((total, payment) => total + payment.agreedModel, 0)
                  .toFixed(2)}`}
                description="BVGE Model"
                avatarBackground="#9E9E9E"
                iconColor="#FFF"
              />
            </Grid>
            {!isMobile && (
              <Grid item xs={12}>
                <CoachInsightsProfitChart
                  lineChartData={additionalRevenueToFacility}
                  title={`${selectedYear} Total Revenue to Club`}
                  value={getTotalRevenueToClub()}
                  // TODO: Add link to learn more about models
                  infoText={
                    <span>
                      This is the information about what this specific model
                      means and how it was calculated.{' '}
                      <u>
                        <b>Learn about models</b>
                      </u>
                    </span>
                  }
                />
              </Grid>
            )}
            {isMobile && (
              <Grid item xs={12}>
                <CoachInsightsFacilityChart
                  facilityData={facilityConstants}
                  selectedOption={{
                    label: 'PGA Suggested Model',
                    value: 'pgaSuggestedModel',
                  }}
                  title={`${selectedYear} Total Revenue to Club`}
                  value={`${formatMoneyFromCents(
                    facilityConstants?.directPaymentToFacilityInCents,
                  )}`}
                />
              </Grid>
            )}
            {isMobile && (
              <Grid item xs={12}>
                <CoachInsightsFacilityChart
                  facilityData={facilityConstants}
                  selectedOption={{ label: 'BVGE Model', value: 'agreedModel' }}
                  title={`${selectedYear} Total Revenue to Club`}
                  value={`${formatMoneyFromCents(
                    facilityConstants?.directPaymentToFacilityInCents,
                  )}`}
                />
              </Grid>
            )}
            {isMobile && (
              <Grid item xs={12}>
                <CoachInsightsFacilityChart
                  facilityData={facilityConstants}
                  selectedOption={{ label: 'BVGI Model', value: 'bvgiModel' }}
                  title={`${selectedYear} Total Revenue to Club`}
                  value={`${formatMoneyFromCents(
                    facilityConstants?.directPaymentToFacilityInCents,
                  )}`}
                />
              </Grid>
            )}
            <Grid item xs={6} sm={3}>
              <CoachInsightsStatsCard
                icon={MonetizationOnOutlinedIcon}
                value={`${formatMoneyFromCents(
                  facilityConstants?.directPaymentToFacilityInCents?.reduce(
                    (total, payment) => total + payment.value,
                    0,
                  ),
                )}`}
                description="Payments from Club"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <CoachInsightsStatsCard
                icon={AccessTimeOutlinedIcon}
                value={`${facilityConstants?.hoursWithProfessional}`}
                description="Hours with Professional"
                valueTestId="hours-professional-value"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <CoachInsightsStatsCard
                icon={GroupOutlinedIcon}
                value={`${facilityConstants?.totalStudents}`}
                description="Unique Participants"
                valueTestId="total-students-value"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <CoachInsightsStatsCard
                icon={PersonAddAltOutlinedIcon}
                value={`${facilityConstants?.newStudents}`}
                description="New Students"
                valueTestId="new-students-value"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <CoachInsightsStatsCard
                icon={GroupAddOutlinedIcon}
                value={`${facilityConstants?.participantsTurnedMembers}`}
                description="Student Convert to Member"
                valueTestId="converted-members-value"
              />
            </Grid>
            {!isMobile && (
              <Grid item xs={9}>
                <CoachInsightsCreateCard
                  icon={mapMarkerEvent}
                  title="Create Group Event"
                  description="Create an engaging group event for your community"
                />
              </Grid>
            )}
          </Grid>
        ) : (
          <div
            data-testid="facility-report-empty-state"
            className={classes.gridContainer}
          >
            {!facilityConstantsLoading && (
              <EmptyPage
                actionFn={() => {}}
                IconComp={ShowChartIcon}
                title="My Facility Report"
                caption="There is currently no facility report data available for this time period."
                buttonText="Create Report"
                actionFnSecondary={() => {}}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default MyFacilityReport
