import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  createReportButton: {
    marginRight: '0',
  },
  actionButton: {
    borderColor: '#3268A7',
    margin: '0',
    color: '#3268A7',
  },
  gridContainer: {
    padding: '20px',
    maxWidth: '1090px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    fontFamily: 'Roboto',
    [theme.breakpoints.down('sm')]: {
      gap: '16px',
    },
    '& h3': {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '32px',
      margin: '0',
    },
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 24px 20px 24px',
    borderBottom: '1px solid #E0E0E0',
  },
  modalTitleSection: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    '& h3': {
      lineHeight: '32px',
    },
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
  modalContent: {
    padding: '0',
    '&:first-child': {
      padding: '0',
    },
  },
  modalHeaderIcon: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: '#F4F6F8',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBody: {
    padding: '18px 24px 10px 24px',
    gap: '22px',
    '& > p': {
      color: '#0000008A',
      fontSize: '14px',
      lineHeight: '22px',
    },
    '& h6': {
      fontSize: '16px',
      lineHeight: '28px',
      fontWeight: 500,
    },
  },
  modalActions: {
    padding: '8px 24px 20px 24px',
    gap: '8px',
  },
  mobileFooter: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '16px',
    backgroundColor: '#fff',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default useStyles
