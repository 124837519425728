import React, { useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import LocationDropdown from '../../../schedule/SelectLessonType/location-dropdown'
import { get } from 'lodash'
import BookingTypesList from './booking-types-list'
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined'
import EmptyBookingTypes from 'components/empty-page-state'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import useStyles from './styles'

const GET_LESSON_TYPES_AND_COACH_FACILITIES = gql`
  query BookingTypesAndCoachAvailabilities {
    lessonTypes {
      id
      slug
      category
      title
      durationInMinutes
      locations {
        id
        name
      }
      coach {
        externalId
        coachProfile {
          slug
        }
      }
      status
      lessonPacks {
        quantity
        priceInCents
        expirationDuration
      }
      accessCode
      giftingEnabled
    }
    coachAvailabilities {
      id
      coachFacility {
        id
        name
      }
      timeSlots {
        day
        startTime
        endTime
      }
    }
  }
`

const BookingTypesViewWrapper = ({ children }) => (
  <>
    <Grid item md={4} xs={12}>
      <Typography variant="h5">
        <Box fontWeight={500}>Booking types</Box>
      </Typography>
    </Grid>
    <Grid item md={7} xs={12}>
      <Box my={2}>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Select a facility from the dropdown below to see the booking types
          associated with that facility. You can also create a new booking type.
        </Typography>
      </Box>
    </Grid>
    {children}
  </>
)

const BookingTypesView = () => {
  const classes = useStyles()
  const history = useHistory()
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'))
  const [selectedFacility, setSelectedFacility] = useState({
    id: 'all',
    name: 'All locations',
  })
  const [coachFacilities, setCoachFacilities] = useState([])
  const [bookingTypes, setBookingTypes] = useState([])
  const { data, loading } = useQuery(GET_LESSON_TYPES_AND_COACH_FACILITIES)

  useEffect(() => {
    if (loading === false && data) {
      const coachAvailabilities = data.coachAvailabilities || []
      const cf = coachAvailabilities.map(cf => {
        return { id: cf.coachFacility.id, name: cf.coachFacility.name }
      })
      setBookingTypes(data.lessonTypes)
      setCoachFacilities(cf)
      setSelectedFacility(get(cf, '0.id', null))
    }
  }, [loading, data])

  if (loading) {
    return (
      <BookingTypesViewWrapper>
        <LinearProgress />
      </BookingTypesViewWrapper>
    )
  }

  const isEmpty = bookingTypes.length === 0

  const hasAvailabilityConfigured = data.coachAvailabilities.some(
    a => a.timeSlots.length > 0,
  )

  if (isEmpty && hasAvailabilityConfigured) {
    return (
      <EmptyBookingTypes
        actionFn={() => {
          history.push('/pga-coach/bookings/booking-types/new')
        }}
        IconComp={() => <LibraryBooksOutlinedIcon fontSize="large" />}
        title="Create a booking type"
        caption="Take the first step to online customer bookings. A booking type represents a coaching program you offer (e.g. private instruction). Get started today!"
        buttonText="Create Booking Type"
        removeButton={isMobile}
      />
    )
  }

  if (isEmpty && !hasAvailabilityConfigured) {
    return (
      <EmptyBookingTypes
        actionFn={() => {
          history.push('/pga-coach/settings/availability')
        }}
        IconComp={() => <LibraryBooksOutlinedIcon fontSize="large" />}
        title="Create a booking type"
        caption="Before creating a booking type, you must set your availability"
        buttonText="Set Availability"
        removeButton={isMobile}
      />
    )
  }

  return (
    <BookingTypesViewWrapper>
      <Grid item lg={4} md={6} xs={12}>
        <Box className={classes.locationWrapper}>
          <LocationDropdown
            selectedFacility={selectedFacility}
            setSelectedFacility={setSelectedFacility}
            locations={coachFacilities}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <BookingTypesList
          selectedFacility={selectedFacility}
          bookingTypes={bookingTypes}
        />
      </Grid>
    </BookingTypesViewWrapper>
  )
}

export default BookingTypesView
