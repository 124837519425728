import React, { useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useHistory } from 'react-router-dom'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined'
import Divider from '@material-ui/core/Divider'
import RedeemIcon from '@mui/icons-material/Redeem'
import { useStyles } from './styles'

const GIFT_LESSON_PACK = gql`
  mutation GiftLessonPack($input: TransferLessonCreditInput!) {
    transferLessonCredit(input: $input) {
      success
      message
    }
  }
`

const GiftLessonPackModal = ({ open, onClose, lessonPack }) => {
  const classes = useStyles()
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const [confirmAccuracy, setConfirmAccuracy] = useState(false)

  const [giftLessonPack, { loading: mutationLoading }] = useMutation(
    GIFT_LESSON_PACK,
    {
      onCompleted: data => {
        setSuccess(data.transferLessonCredit.success)
        setError(
          data.transferLessonCredit.success
            ? ''
            : data.transferLessonCredit.message,
        )
      },
    },
  )

  const handleSubmit = e => {
    e.preventDefault()
    setError('')

    if (!confirmAccuracy) {
      setError('Please confirm the accuracy of the information')
      return
    }

    giftLessonPack({
      variables: {
        input: {
          lessonPackId: lessonPack.id,
          email,
          firstName,
          lastName,
          lessonCreditCount: parseInt(lessonPack.totalCredits),
        },
      },
    })
  }

  const handleClose = () => {
    setEmail('')
    setFirstName('')
    setLastName('')
    setError('')
    setSuccess(false)
    setConfirmAccuracy(false)
    onClose()
  }

  const handlePurchaseMore = () => {
    const { coach, id: lessonTypeId, locations } = lessonPack.lessonType
    const locationId = locations[0].id
    const relativeBookingUrl = `/coach${
      coach.bookingUrl.split('/coach')[1]
    }?lessonTypeId=${lessonTypeId}&locationId=${locationId}&isLessonPacks=true`
    history.push(relativeBookingUrl)
    handleClose()
  }

  const handleHome = () => {
    history.push('/club')
    handleClose()
  }

  const isFormValid = email && firstName && lastName && confirmAccuracy

  if (success) {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.successContent}>
            <Box className={classes.celebrationIcon}>
              <CelebrationOutlinedIcon />
            </Box>
            <Typography variant="h6" gutterBottom>
              Success!
            </Typography>
            <Typography variant="body1" paragraph>
              The recipient has been notified to accept the lesson pack. You
              will receive a confirmation once the credits are successfully
              transferred to their account. Please note, the acceptance link
              will expire in 7 days. If it is not accepted by then, you'll
              receive a notice when the pack is added back to your account.
            </Typography>
            <Box className={classes.successActions}>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePurchaseMore}
                className={classes.successButton}
              >
                Purchase Another Pack
              </Button>
              <Button
                color="primary"
                onClick={handleHome}
                className={classes.homeButton}
              >
                Home
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.dialogTitle}>
        Gift Lesson Pack
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.form}>
          <Typography variant="body1" className={classes.description}>
            If you wish to cancel the pack before acceptance, please reach out
            to support at support@pga.org. Once accepted, it's final.{' '}
            <strong>The recipient will be notified.</strong>
          </Typography>

          {error && (
            <Alert severity="error" className={classes.alert}>
              {error}
            </Alert>
          )}

          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  className={classes.field}
                  label="Recipient First Name"
                  type="text"
                  fullWidth
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.field}
                  label="Recipient Last Name"
                  type="text"
                  fullWidth
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  required
                />
              </Grid>
            </Grid>

            <TextField
              className={classes.field}
              label="Recipient Email"
              type="email"
              fullWidth
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmAccuracy}
                  onChange={e => setConfirmAccuracy(e.target.checked)}
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  Confirm the accuracy of the above information by checking this
                  box.
                </Typography>
              }
            />

            <Box className={classes.buttonContainer}>
              <Button onClick={handleClose} variant="text">
                Cancel
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={mutationLoading || !isFormValid}
              >
                {mutationLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    <RedeemIcon className={classes.redeemIcon} />
                    Gift Lessons
                  </>
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default GiftLessonPackModal
