import React, { useEffect, useState, useCallback } from 'react'
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'
import { gtmEvent } from 'lib/gtm'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { refreshedCategories } from 'components/lesson-type-category/utils'
import { defineMinimumPricePerLesson } from 'utils/defineMinimumPricePerLesson'
import BookingDetails from './booking-details-dialog'
import VerifyAccessCode from '../verify-access-code'
import ChipsSection from './chips-section'
import GolfCourseOutlinedIcon from '@material-ui/icons/GolfCourseOutlined'
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import IconButton from '@material-ui/core/IconButton'
import { IconInfoActive } from 'components/atoms/icons'
import { makeStyles, useTheme } from '@material-ui/styles'
import RequestAccessCode from '../request-access-code'
import PriceDetails from './price-details'
import { durationText } from 'utils/durationUtils'

const useStyles = makeStyles(theme => ({
  cardroot: ({ shouldBeClickable }) => ({
    height: '17rem',
    width: '300px',
    borderRadius: '.5rem',
    cursor: shouldBeClickable ? 'pointer' : 'default',
    position: 'relative',
    transition: 'all 0.1s ease',
    boxShadow: '0px 4px 36px rgb(0 10 22 / 13%)',
    '&:hover': {
      boxShadow: '0px 4px 36px rgb(0 10 22 / 32%)',
    },
    [theme.breakpoints.only('xs')]: {
      width: '90%',
    },
  }),
  cardHeader: {
    height: '50px',
    padding: '0 25px',
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '700',
    fontSize: '0.75rem',
  },
  cardIcon: {
    position: 'absolute',
    color: theme.palette.secondary.dark,
    backgroundColor: '#FFFFFF',
    top: '23px',
    right: '25px',
    height: '56px',
    width: '56px',
    border: `2px solid ${theme.palette.secondary.light}`,
    '& svg': {
      fontSize: '2rem',
    },
  },
  title: {
    color: theme.palette.primary.main,
    height: '2.5rem',
    paddingBottom: '2.5rem',
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '4rem 1.25rem 1rem 1.25rem',
    backgroundColor: '#FFFFFF',
    height: 'inherit',
  },
  attributes: {
    color: theme.palette.grey.dark,
    fontWeight: 600,
    fontSize: '0.75rem',
    paddingLeft: '.5rem',
    marginBottom: 0,
    marginTop: 0,
  },
  listItem: {
    padding: '0',
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  listIcon: {
    fontSize: '1rem',
    color: theme.palette.secondary.dark,
  },
  infoIcon: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  purchased: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.main,
  },
}))

const LessonTypeCardRefresh = ({
  lessonInfo,
  bookingUrl,
  shouldBeClickable,
  coachName,
  coachExternalId,
  isSingleSession,
  lessonCreditBalance,
  coachIsViewingOwnSchedule,
}) => {
  const {
    id,
    title,
    durationInMinutes,
    locations,
    locationName,
    locationPlace,
    priceInCents,
    category = 'OTHER',
    status,
    lessonPacks = [],
    requiresAccessCode,
    coach,
    giftingEnabled,
  } = lessonInfo

  const classes = useStyles({ shouldBeClickable })
  const theme = useTheme()

  const [openDetails, setOpenDetails] = useState(false)
  const [openLessonPackDetails, setOpenLessonPackDetails] = useState(false)
  const [openVerifyAccessCode, setOpenVerifyAccessCode] = useState(false)
  const [verifiedAccessCode, setVerifiedAccessCode] = useState(false)
  const [openRequestAccessCode, setOpenRequestAccessCode] = useState(false)
  const history = useHistory()

  const lessonCreditBalanceQuantity = get(lessonCreditBalance, '0.quantity')
  const nextExpirationDate = get(lessonCreditBalance, '0.nextExpirationDate')
  const withLessonPacksCard = lessonPacks.length > 0

  const lessonPacksPrice = defineMinimumPricePerLesson(lessonPacks)

  const handleDetailsClick = e => {
    e.preventDefault()
    e.stopPropagation()
    gtmEvent({
      event: 'click-booking-info-button',
      booking_type: category,
      facility: locationName,
      duration: durationInMinutes,
      title: title,
    })
    withLessonPacksCard ? setOpenLessonPackDetails(true) : setOpenDetails(true)
    e.currentTarget.blur() // to remove retained focus after interaction
  }

  const handleCardClick = useCallback(
    e => {
      if (!shouldBeClickable) return
      if (
        requiresAccessCode &&
        !verifiedAccessCode &&
        !lessonCreditBalanceQuantity
      ) {
        setOpenVerifyAccessCode(true)
        return
      }
      gtmEvent({
        event: withLessonPacksCard
          ? 'select-lesson-pack'
          : 'select-private-session',
        title: title,
        category: category,
        facility: locationName,
        location: locationPlace,
        price: `${priceInCents / 100}`,
        event_date: null,
        event_time: null,
        duration: durationInMinutes,
        coach_id: coach?.id,
        coach_name: coachName,
        access_code_required: requiresAccessCode ? 'yes' : 'no',
      })

      let redirectUrl =
        isSingleSession || coachIsViewingOwnSchedule
          ? bookingUrl
          : `${bookingUrl}&isLessonPacks=true`
      redirectUrl = lessonCreditBalanceQuantity
        ? `${redirectUrl}&lessonCreditBalanceQuantity=${lessonCreditBalanceQuantity}`
        : redirectUrl
      history.push(redirectUrl, { verifiedAccessCode: true })
      // Remove the below event in the future.
      gtmEvent({
        event: 'formSubmit',
        formCategory: 'book-session',
        formAction: 'selected-booking-type',
        metaData: {
          ...lessonInfo,
        },
      })
    },
    [
      bookingUrl,
      history,
      isSingleSession,
      lessonCreditBalanceQuantity,
      lessonInfo,
      requiresAccessCode,
      shouldBeClickable,
      verifiedAccessCode,
      coachIsViewingOwnSchedule,
      withLessonPacksCard,
      title,
      category,
      locationName,
      locationPlace,
      priceInCents,
      durationInMinutes,
      coach,
      coachName,
    ],
  )

  useEffect(() => {
    verifiedAccessCode && handleCardClick()
  }, [verifiedAccessCode, handleCardClick])

  const showLessonPackPrice =
    (!isSingleSession && withLessonPacksCard) ||
    (isSingleSession && !lessonCreditBalanceQuantity && !!lessonPacksPrice)
  return (
    <>
      <Card
        key={id}
        className={classes.cardroot}
        onClick={handleCardClick}
        data-testid="lesson-card"
      >
        <Typography
          className={`${classes.cardHeader} ${
            lessonCreditBalanceQuantity ? classes.purchased : ''
          }`}
        >
          {lessonCreditBalanceQuantity
            ? 'Purchased'
            : category
              .toLowerCase()
              .replace(/_/g, ' ')
              .split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')}
        </Typography>
        <Avatar className={classes.cardIcon}>
          {refreshedCategories[category].icon}
        </Avatar>
        {shouldBeClickable && (
          <Grid item xs={12}>
            <Grid item xs={12} lg={6}>
              <ChipsSection
                status={status}
                requiresAccessCode={requiresAccessCode}
                lessonCreditBalanceQuantity={lessonCreditBalanceQuantity}
                isSingleSession={isSingleSession}
                withLessonPacksCard={withLessonPacksCard}
                nextExpirationDate={nextExpirationDate}
                giftingEnabled={giftingEnabled}
              />
            </Grid>
            <Grid className={classes.cardBody}>
              <Typography
                variant="h6"
                color="primary"
                className={classes.title}
              >
                {title}
              </Typography>
              <List dense={true} disablePadding={true} className={classes.list}>
                <ListItem disableGutters={true} className={classes.listItem}>
                  <GolfCourseOutlinedIcon className={classes.listIcon} />
                  <Typography
                    className={classes.attributes}
                    variant="body2"
                    noWrap
                  >
                    {locations[0].name}
                  </Typography>
                </ListItem>
                <ListItem disableGutters={true} className={classes.listItem}>
                  <TimerOutlinedIcon className={classes.listIcon} />
                  <Typography className={classes.attributes} variant="body2">
                    {durationText(durationInMinutes)}
                  </Typography>
                </ListItem>
                <PriceDetails
                  lessonInfo={lessonInfo}
                  showLessonPackPrice={showLessonPackPrice}
                  lessonPacksPrice={lessonPacksPrice}
                />
              </List>
            </Grid>
          </Grid>
        )}
        <IconButton onClick={handleDetailsClick} className={classes.infoIcon}>
          <IconInfoActive fill={theme.palette.primary.light} />
        </IconButton>
      </Card>
      {openDetails && (
        <BookingDetails
          lessonInfo={lessonInfo}
          isOpen={openDetails}
          setIsOpen={setOpenDetails}
          bookingUrl={bookingUrl}
          coachExternalId={coachExternalId}
          coachName={coachName}
          isSingleSession={isSingleSession}
          setOpenRequestAccessCode={setOpenRequestAccessCode}
          setOpenVerifyAccessCode={setOpenVerifyAccessCode}
          coachIsViewingOwnSchedule={coachIsViewingOwnSchedule}
        />
      )}
      {openLessonPackDetails && (
        <BookingDetails
          lessonInfo={lessonInfo}
          isOpen={openLessonPackDetails}
          setIsOpen={setOpenLessonPackDetails}
          bookingUrl={bookingUrl}
          coachName={coachName}
          coachExternalId={coachExternalId}
          nextExpirationDate={nextExpirationDate}
          lessonCreditBalanceQuantity={lessonCreditBalanceQuantity}
          isSingleSession={isSingleSession}
          setOpenRequestAccessCode={setOpenRequestAccessCode}
          setOpenVerifyAccessCode={setOpenVerifyAccessCode}
          coachIsViewingOwnSchedule={coachIsViewingOwnSchedule}
        />
      )}
      {requiresAccessCode && openVerifyAccessCode && (
        <VerifyAccessCode
          lessonType={lessonInfo}
          isOpen={openVerifyAccessCode}
          setIsOpen={setOpenVerifyAccessCode}
          coachName={coachName}
          setVerifiedAccessCode={setVerifiedAccessCode}
        />
      )}
      {requiresAccessCode && openRequestAccessCode && (
        <RequestAccessCode
          isOpen={openRequestAccessCode}
          setIsOpen={setOpenRequestAccessCode}
          coachName={coachName}
          coachExternalId={coachExternalId}
          bookingUrl={bookingUrl}
        />
      )}
    </>
  )
}

export { useStyles }
export default LessonTypeCardRefresh
