import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  inputContainer: {
    display: 'flex',
    gap: 16,
  },
}))

export const mobileTabsContainerStyles = {
  width: '90vw',
  maxWidth: '100%',
  position: 'relative',
  left: '50%',
  transform: 'translateX(-50%)',
  overflow: 'hidden',
  display: { xs: 'block', md: 'none' },
  mb: { xs: 2 },
}

export const tabsStyles = {
  width: '100%',
  '& .MuiTabs-flexContainer': {
    width: 'fit-content',
  },
  '& .MuiTab-root': {
    textTransform: 'none',
    minWidth: 'auto',
    px: 1,
    fontSize: '0.875rem',
    '&.Mui-selected': {
      color: '#002855',
    },
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#002855',
  },
}

export const monthSelectionPaperStyles = {
  p: 2,
  width: '90%',
  margin: '0 auto',
  mb: 4,
  boxShadow: 'none',
  border: '1px solid #E0E0E0',
  display: { xs: 'none', md: 'block' },
}

export const toggleButtonGroupStyles = {
  flexWrap: 'wrap',
  gap: 1,
  '& .MuiToggleButton-root': {
    borderRadius: '24px !important',
    bgcolor: '#f5f5f5',
    border: 'none',
    color: 'text.primary',
    px: 3,
    py: 1,
    '&.Mui-selected': {
      bgcolor: '#002855',
      color: 'white',
      '&:hover': {
        bgcolor: '#002855',
      },
    },
    '&:hover': {
      bgcolor: '#e0e0e0',
    },
  },
}

export const nextMonthPaperStyles = {
  p: 2,
  bgcolor: '#F8FBFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: 'none',
  boxShadow: 'none',
}

export const nextMonthIconContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: 3,
}

export const nextMonthIconStyles = {
  color: '#043362',
}

export const nextMonthTextStyles = {
  color: '#000000DE',
}

export const introTextStyles = {
  mb: { xs: 2, md: 4 },
}

export const checkIconStyles = {
  mr: 0.5,
  fontSize: '1rem',
}

export default useStyles
