import useStyles from './styles'

/*
 * CoachInsightsStatsCard Component
 *
 * This component displays a card with an icon, a value, and a description.
 *
 * Props:
 * - icon: A React component to render as the icon.
 * - value: The value to be displayed prominently in the card.
 * - description: A brief description or label for the value.
 */
const CoachInsightsStatsCard = ({
  icon: Icon,
  value,
  description,
  avatarBackground,
  iconColor,
  valueTestId,
  ...props
}) => {
  const classes = useStyles()

  return (
    <div data-testid={valueTestId} className={classes.card}>
      <div className={classes.cardContent}>
        {Icon ? (
          <div
            className={classes.avatar}
            style={{ backgroundColor: avatarBackground }}
          >
            <Icon className={classes.icon} style={{ color: iconColor }} />
          </div>
        ) : (
          <div /> // Create a div to make the card content align
        )}
        <div className={classes.valueContainer}>
          <p className={classes.value}>{value}</p>
          <p className={classes.description}>{description}</p>
        </div>
      </div>
    </div>
  )
}

export default CoachInsightsStatsCard
