import { gql } from '@apollo/client'

export const CoachScheduleAvailability = gql`
  query coachScheduleAvailability($id: ID!, $timespan: DateTimeRangeInput!) {
    coach(id: $id) {
      scheduleAvailability(timespan: $timespan) {
        isAvailable
        warnings
      }
    }
  }
`
export const GetStudentQuery = gql`
  query GetStudentQuery($id: ID!) {
    student(id: $id) {
      id
      firstName
      lastName
      golferProfile {
        id
      }
    }
  }
`
export const LessonAvailabilityQuery = gql`
  query LessonAvailabilityQuery(
    $id: ID!
    $startDateTime: ISO8601DateTime!
    $endDateTime: ISO8601DateTime!
    $locationId: ID!
  ) {
    lessonTypes(id: $id) {
      id
      category
      title
      durationInMinutes
      description
      locations {
        id
        name
        city
        state
      }
      facilities {
        id
        name
        city
        state
        timezone
      }
      lessonPacks {
        quantity
        priceInCents
      }
      priceInCents
      availableTimes(
        startDateTime: $startDateTime
        endDateTime: $endDateTime
        locationId: $locationId
      ) {
        startDateTime
        locationId
      }
      coach {
        id
        name
        externalId
        facilities {
          id
          name
        }
        coachProfile {
          title
          firstName
          lastName
          city
          state
          profilePhoto
          slug
        }
      }
      requiresAccessCode
    }
  }
`
export const LessonReviewQuery = gql`
  query LessonReviewQuery($id: ID!) {
    lessonTypes(id: $id) {
      id
      academy {
        slug
      }
      category
      title
      durationInMinutes
      priceInCents
      facilities {
        id
        name
        timezone
        city
        state
      }
      coach {
        id
        name
        bookingUrl
        externalId
        coachProfile {
          title
          profilePhoto
        }
      }
      acceptsOnlinePayments
      lessonPacks {
        quantity
        priceInCents
      }
    }
  }
`

export const GET_LESSON_CREDIT_BALANCE = gql`
  query lessonCreditBalances {
    currentUser {
      attributes {
        ... on Contact {
          lessonCreditBalances {
            lessonType {
              id
              title
            }
            quantity
            nextExpirationDate
          }
          giftedLessonCreditBalances {
            lessonType {
              id
              title
            }
            quantity
            nextExpirationDate
          }
        }
      }
    }
  }
`
