import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  form: {
    padding: theme.spacing(2),
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
    height: 48,
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  successActions: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  buttonContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
    justifyContent: 'flex-end',
  },
  description: {
    marginBottom: theme.spacing(3),
  },
  successContent: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  celebrationIcon: {
    borderRadius: '50%',
    background: 'rgba(0,25,75,.08)',
    padding: '1rem',
    color: theme.palette.primary.main,
    width: '83px',
    height: '83px',
    boxSizing: 'border-box',
    margin: '12px auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fontSize: '40px',
    },
  },
  dialogTitle: {
    marginTop: 20,
    marginLeft: 38,
    marginBottom: 14,
    padding: 0,
  },
  redeemIcon: {
    marginRight: theme.spacing(1),
  },
  successButton: {
    padding: '10px 14px',
  },
  homeButton: {
    marginBottom: theme.spacing(2),
  },
}))
