import { makeStyles } from '@material-ui/core/styles'
import PGAIconSVG from 'images/pga-seal-icon.svg'
import MemberLogo from 'images/coach-logos/pga-member-logo.svg'
import MasterLogo from 'images/coach-logos/pga-master-logo.svg'

const getPgaCrest = {
  CERTIFIED: MemberLogo,
  SPECIALIZED: MemberLogo,
  MASTER: MasterLogo,
  MEMBER: MemberLogo,
}

const useStyles = makeStyles(theme => ({
  logo: {
    width: '80px',
    height: '80px',
    [theme.breakpoints.down('md')]: {
      width: '60px',
    },
  },
}))

const CoachLogo = ({ memberType, certificationLevel }) => {
  const classes = useStyles()
  if (memberType === 'QE') return null
  if (memberType === 'AP' || memberType === 'ST') {
    return <img src={PGAIconSVG} alt="PGA Crest" className={classes.logo} />
  }
  return certificationLevel ? (
    <img
      src={getPgaCrest[certificationLevel]}
      alt="PGA Crest"
      className={classes.logo}
    />
  ) : null
}

export default CoachLogo
