import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import useStyles from './styles'

/*
 * Expected data format for barChartData:
 * An array of objects where each object represents a data point.
 * The keys of the objects will be used as data keys for the bars in the chart.
 * Example:
 * [
 *   { key1: value1, key2: value2, ... },
 *   { key1: value3, key2: value4, ... },
 *   ...
 * ]
 */
const CoachInsightsYTDChart = ({ barChartData, title, value }) => {
  const classes = useStyles()

  const COLORS = ['#043362', '#3268A7', '#9E9E9E', '#007AFF']

  const formatKey = key => {
    return key
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/^./, str => str.toUpperCase())
  }

  return (
    <div className={classes.card}>
      <div className={classes.cardContent}>
        <p className={classes.description}>{title}</p>
        <p className={classes.value}>{value}</p>
        <ResponsiveContainer
          width="100%"
          height={140}
          data-testid="chart-container"
        >
          <BarChart data={barChartData} margin={{ left: -15 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="month"
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12 }}
            />
            <YAxis axisLine={false} tickLine={false} tick={{ fontSize: 12 }} />
            <Tooltip />
            <Legend wrapperStyle={{ fontSize: '14px' }} />
            {barChartData[0] &&
              Object.keys(barChartData[0])
                ?.filter(key => key !== 'month')
                ?.map((key, index) => (
                  <Bar
                    dataKey={key}
                    stackId="a"
                    fill={COLORS[index % COLORS.length]}
                    name={formatKey(key)}
                  />
                ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default CoachInsightsYTDChart
