import React from 'react'
import { Typography, Box } from '@material-ui/core'
import useStyles from './styles'
import BookingTypeVisibility from './booking-type-visibility'
import BookingTypeAccessCode from './booking-type-access-code'
import BookingTypeGifting from './booking-type-gifting'
import MUIRichTextEditor from 'mui-rte'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'
import { convertToRaw } from 'draft-js'
import { Controller } from 'react-hook-form'
import { flags, useFeatureFlags } from 'lib/feature-flags'

const BookingTypeAdditionalOptions = ({
  errors,
  control,
  register,
  setValue,
  status,
  setStatus,
  bookingType,
  isLessonPacks,
  giftingEnabled,
  setGiftingEnabled,
}) => {
  const classes = useStyles()
  const [giftingFlagEnabled] = useFeatureFlags([
    flags.FLAG_FEAT_LESSON_PACK_GIFTING,
  ])

  return (
    <>
      {giftingFlagEnabled && isLessonPacks && (
        <BookingTypeGifting
          giftingEnabled={giftingEnabled}
          setGiftingEnabled={setGiftingEnabled}
        />
      )}
      <BookingTypeVisibility status={status} setStatus={setStatus} />
      <BookingTypeAccessCode
        bookingType={bookingType}
        classes={classes}
        register={register}
        errors={errors}
        setValue={setValue}
      />
      <Typography variant="subtitle1" gutterBottom>
        Confirmation Message
      </Typography>
      <Typography variant="body2" gutterBottom>
        This message will appear on the confirmation email to your student. Use
        this space to communicate the next steps to your student (where to meet,
        video url, anything else that is important to the session).
      </Typography>
      <Box className={classes.editorRoot}>
        <Controller
          render={({ onChange, value }, _) => (
            <>
              <MUIRichTextEditor
                label="Create confirmation"
                controls={['bold', 'italic', 'strikethrough', 'bulletList']}
                defaultValue={JSON.stringify(
                  markdownToDraft(
                    control.defaultValuesRef.current.confirmationMessage,
                  ),
                )}
                onChange={e => {
                  onChange(e)
                  const content = convertToRaw(e.getCurrentContent())
                  setValue('confirmationMessage', draftToMarkdown(content, {}))
                }}
              />
            </>
          )}
          name="confirmationMessage"
          control={control}
          defaultValue={null}
        />
      </Box>
    </>
  )
}

export default BookingTypeAdditionalOptions
