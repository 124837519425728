import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() => ({
  root: {
    backgroundColor: '#EBEFF5',
    padding: '1.5rem 2rem',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '144px',
    border: '1px solid #E0E0E0',
    cursor: 'pointer',
  },
  title: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '28px',
    color: '#000000DE',
    margin: 0,
    marginTop: '1rem',
  },
  description: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '22px',
    color: '#000000DE',
    margin: 0,
  },
}))

export default styles
