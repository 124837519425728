import { useState } from 'react'
import { Box, Button, Hidden } from '@material-ui/core'
import { PageHeader } from 'components/page-header'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import MyFacilityReport from './my-facility-report'
import useStyles from './styles'
import MyBusinessInsights from './my-business-insights'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useParams } from 'react-router-dom'
import {
  GetBusinessInsightsByReportIdQuery,
  GetFacilityConstantsByReportIdQuery,
} from '../reports/query'
import { useQuery } from '@apollo/client'
import { useMockQuery } from '../use-mock-query'

const CoachBusinessReportsDashboard = () => {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0)
  const { reportId } = useParams()

  // Always call the hooks
  const mockData = useMockQuery()
  const { loading: queryLoading, data: queryData } = useQuery(
    GetFacilityConstantsByReportIdQuery,
    {
      variables: { reportId },
      skip: !reportId, // Skip the real query for sample reports
    },
  )

  const { loading: insightsQueryLoading, data: insightsQueryData } = useQuery(
    GetBusinessInsightsByReportIdQuery,
    {
      variables: { reportId },
      skip: !reportId, // Skip the real query for sample reports
    },
  )

  // Use mock or real data based on whether it's a sample report
  const facilityConstantsLoading = !reportId ? false : queryLoading
  const facilityConstantsData = !reportId
    ? { facilityValue: mockData.facility }
    : queryData

  const businessInsightsLoading = !reportId ? false : insightsQueryLoading
  const businessInsightsData = !reportId
    ? { businessInsights: mockData.data }
    : insightsQueryData

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <PageHeader
        title="My Business Reporting"
        crumbs={[{ label: 'Reports', to: '/pga-coach/reports' }]}
        actions={
          <div style={{ marginRight: '0' }}>
            <Hidden smDown>
              <Button
                variant="outlined"
                color="primary"
                className={classes.exportButton}
              >
                Export
              </Button>
            </Hidden>
            <Hidden mdUp>
              <FileUploadOutlinedIcon style={{ color: '#0000008A' }} />
            </Hidden>
          </div>
        }
        className={classes.pageHeader}
      />
      <TabContext value={String(tabValue)}>
        <Box className={classes.tabContainer}>
          <TabList onChange={handleTabChange}>
            <Tab label="My Business Insights" value="0" />
            <Tab label="My Facility Report" value="1" />
          </TabList>
        </Box>
        <TabPanel value="0">
          <MyBusinessInsights
            businessInsightsLoading={businessInsightsLoading}
            businessInsights={businessInsightsData?.businessInsights}
          />
        </TabPanel>
        <TabPanel value="1">
          <MyFacilityReport
            facilityConstantsLoading={facilityConstantsLoading}
            facilityConstants={facilityConstantsData?.facilityValue}
          />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default CoachBusinessReportsDashboard
