import PropTypes from 'prop-types'
import * as Yup from 'yup'
import usStates from 'utils/usStates'
import { flatMap } from 'lodash'
import { gql } from '@apollo/client'
import { apolloClient } from 'lib/graphql'

export const gendersList = [
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' },
  { value: 'O', label: 'Other' },
]

export const socialsList = [
  {
    value: 'IG',
    label: 'Instagram',
    placeholder: 'www.instagram.com/',
    fullURL: 'https://www.instagram.com/',
  },
  {
    value: 'FB',
    label: 'Facebook',
    placeholder: 'www.facebook.com/',
    fullURL: 'https://www.facebook.com/',
  },
  {
    value: 'YT',
    label: 'YouTube',
    placeholder: 'www.youtube.com/',
    fullURL: 'https://www.youtube.com/',
  },
  {
    value: 'TWT',
    label: 'X.com',
    placeholder: 'www.x.com/',
    fullURL: 'https://www.x.com/',
  },
]

export const initForm = formFields => {
  const myExperiences = flatMap(formFields?.experienceTypes, 'experiences').map(
    ({ experienceId }) => ({ experienceId }),
  )

  const myServices = flatMap(formFields?.serviceTypes, 'services').map(
    ({ serviceId }) => ({ serviceId }),
  )

  const mySkills = (formFields?.skills ?? []).map(({ skillId }) => ({
    skillId,
  }))

  const facilities = formFields?.facilities ?? [
    { customData: { facilityRank: 1 } },
  ]

  return {
    memberId: formFields?.memberId,
    memberType: formFields?.memberType,
    internalUserId: formFields?.internalUserId ?? 1,
    coachProfileId: formFields?.coachProfileId,
    firstName: formFields?.firstName ?? '',
    lastName: formFields?.lastName ?? '',
    displayName: formFields?.displayName ?? '',
    gender: formFields?.gender ?? null,
    title: formFields?.title ?? '',
    prices: formFields?.prices ?? [],
    customSlug: formFields?.customSlug ?? '',
    profilePhoto: formFields?.profilePhoto ?? null,
    email: formFields?.email ?? '',
    phoneNumber: formFields?.phoneNumber ?? '',
    phoneExt: formFields?.phoneExt ?? '',
    mobileNumber: formFields?.mobileNumber ?? '',
    city: formFields?.city ?? '',
    state: formFields?.state ?? '',
    zipCode: formFields?.zipCode ?? '',
    bio: formFields?.bio ?? '',
    numberOfStudents: formFields?.numberOfStudents ?? null,
    numberOfFollowers: formFields?.numberOfFollowers ?? null,
    // schedulingTool: (formFields?.schedulingTool) ?? '',
    socialJsonData: formFields?.socialJsonData ?? [],
    introductionVideoUrl: formFields?.introductionVideoUrl ?? '',
    testimonialsJsonData: formFields?.testimonialsJsonData ?? [],
    photosJsonData: formFields?.photosJsonData ?? [],
    toolJsonData: formFields?.toolJsonData ?? [],
    status: formFields?.status,
    experiences: myExperiences,
    facilities,
    services: myServices,
    skills: mySkills,
  }
}

export const phoneRegExp = /^$|^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/
/*
  phoneRegExp matches:
  123-456-7890
  (123)/456-7890
  123 456 7890
  123.456.7890
  1234567890
  (and similar variations)
  or empty string
*/

export const phoneExtRegExp = /^\d{0,4}$/
// allows up to 4 digits

export const slugRegExp = /^[A-Za-z0-9\-_]+$/
// allows only alpha-numeric, dashes, and underscores

const CustomSlugDBValidator = gql`
  query ValidateCustomSlug($customSlug: CustomSlug!) {
    ValidateCustomSlug(customSlug: $customSlug) {
      message
      success
    }
  }
`
export const verifySlug = async value => {
  const { data } = await apolloClient.query({
    query: CustomSlugDBValidator,
    variables: { customSlug: value },
  })
  return data?.ValidateCustomSlug.success
}

export const slugValidator = verifySlug =>
  Yup.string()
    .max(25)
    .required()
    .matches(
      slugRegExp,
      'Profile Name may only contain letters, numbers, dashes, and underscores',
    )
    .label('Profile Name')
    .test(
      'validated',
      'Sorry, this slug is in use',
      async value => await verifySlug(value),
    )

export const urlHttpExp = /^https?:\/\//
// allows strings starting with http:// or https://

const combineSocials = socialsList
  .map(social => social.fullURL.split('/').join('\\/'))
  .join('|')
// escapes forward slashes, joins social full URLs with OR between them

export const socialRegExp = RegExp(`^(${combineSocials})([A-Za-z0-9-_/.@])+$`)
// allows the first half of the string to be the social's full url, and after
// that just username appropriate chars

export const socialValidator = Yup.array().of(
  Yup.object().shape({
    reference: Yup.string()
      .nullable()
      .matches(socialRegExp, 'Must use a valid social profile or page'),
  }),
)

export const stateRegExp = /^[A-Z]{2}$/
// allows a two letter capitalized value

export const zipRegEx = /^\d{5}([-]?\d{4})?$/

export const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/

const youtubeUrlValidator = Yup.string()
  .label('Video Introduction')
  .nullable()
  .transform((curr, orig) => (orig === '' ? null : curr))
  .matches(urlHttpExp, 'Must be a valid URL')
  .matches(
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/,
    'Must be a valid YouTube video URL.',
  )

const baseValidationShape = {
  firstName: Yup.string()
    .required()
    .max(50)
    .label('First Name'),
  lastName: Yup.string()
    .required()
    .max(100)
    .label('Last Name'),
  displayName: Yup.string()
    .max(100)
    .label('Display Name'),
  title: Yup.string()
    .max(100)
    .label('Title'),
  gender: Yup.string()
    .oneOf([...gendersList.map(gender => gender.value), null])
    .nullable()
    .label('Gender'),
  prices: Yup.array()
    .of(Yup.object())
    .label('Price'),
  email: Yup.string()
    .email()
    .required()
    .max(100)
    .label('Email'),
  phoneNumber: Yup.string()
    .nullable()
    .matches(phoneRegExp, 'Your phone number must be 10 digits')
    .label('Phone Number'),
  phoneExt: Yup.string()
    .nullable()
    .matches(phoneExtRegExp, 'Ext. must be an integer up to 4 digits')
    .label('Ext.'),
  mobileNumber: Yup.string()
    .nullable()
    .matches(phoneRegExp, 'Your phone number must be 10 digits')
    .label('Mobile Number'),
  city: Yup.string()
    .required()
    .max(50)
    .label('City'),
  state: Yup.string()
    .oneOf(usStates, 'Must be a valid US State')
    .required('Must be a valid US State')
    .label('State'),
  zipCode: Yup.string()
    .matches(zipRegEx, 'Must be a valid Zip')
    .required()
    .label('ZipCode'),
  bio: Yup.string()
    .max(5000)
    .label('Personal Bio'),
  numberOfStudents: Yup.number()
    .transform(value => (Number.isInteger(value) ? value : null))
    .nullable()
    .min(0, 'Number of students must be greater than or equal to 0'),
  numberOfFollowers: Yup.number()
    .transform(value => (Number.isInteger(value) ? value : null))
    .nullable()
    .min(0, 'Number of followers must be greater than or equal to 0'),
  socialJsonData: socialValidator,
  introductionVideoUrl: youtubeUrlValidator,
  testimonialsJsonData: Yup.array().of(
    Yup.object().shape({
      testimonial: Yup.string()
        .required()
        .max(240, 'You have exceeded the character limit of 240')
        .label('Testimonial'),
      author: Yup.string()
        .required()
        .label('Attestant'),
    }),
  ),
  facilities: Yup.array().of(
    Yup.object()
      .shape({
        facilityName: Yup.string()
          .required()
          .label('Facility Name'),
        street: Yup.string()
          .required()
          .label('Address'),
        city: Yup.string()
          .required()
          .max(50)
          .label('City'),
        state: Yup.string()
          .required()
          .label('State'),
        zipCode: Yup.string()
          .required()
          .max(10)
          .label('Zipcode'),
        phoneNumber: Yup.string()
          .nullable()
          .label('Phone Number'),
        website: Yup.string()
          .nullable()
          .label('Website'), // TODO add special URL validation
        isPrivate: Yup.boolean()
          .default(false)
          .nullable()
          .label('Private Facility Checkbox'),
      })
      .label('Facilities'),
  ),
  experiences: Yup.array()
    .of(Yup.object())
    .required('You must choose some experiences'),
  services: Yup.array()
    .of(Yup.object())
    .required('You must choose some services'),
  skills: Yup.array()
    .of(Yup.object())
    .required('You must choose some skills'),
  toolJsonData: Yup.array().of(Yup.string().label('Tool Name')),
}

export const createValidationSchema = Yup.object().shape({
  ...baseValidationShape,
  ...{ customSlug: slugValidator(verifySlug) },
})

export const editValidationSchema = Yup.object().shape({
  ...baseValidationShape,
})

export const formFields = PropTypes.shape({
  internalUserId: PropTypes.string,
  coachProfileId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  displayName: PropTypes.string,
  gender: PropTypes.oneOf([...gendersList, ...gendersList.map(g => g.value)]),
  title: PropTypes.string,
  prices: PropTypes.array,
  customSlug: PropTypes.string,
  profilePhoto: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  phoneExt: PropTypes.string,
  mobileNumber: PropTypes.string,
  // schedulingTool: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bio: PropTypes.string,
  numberOfStudents: PropTypes.number,
  numberOFFollowers: PropTypes.number,
  socialJsonData: PropTypes.array,
  introductionVideoUrl: PropTypes.string,
  testimonialsJsonData: PropTypes.array,
  photosJsonData: PropTypes.array,
  toolJsonData: PropTypes.array,
  status: PropTypes.string,
  facilities: PropTypes.array,
  experience: PropTypes.array,
  service: PropTypes.array,
  skills: PropTypes.array,
})
