import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  card: {
    border: '1px solid #E0E0E0',
    padding: '1.5rem',
    textAlign: 'left',
    borderRadius: '12px',
    fontFamily: 'Roboto',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem 1rem',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: 0,
    position: 'relative',
  },
  value: {
    fontSize: '30px',
    marginTop: 0,
    fontWeight: 500,
    marginBottom: '12px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '26px',
      marginBottom: '16px',
    },
  },
  description: {
    fontWeight: 400,
    fontSize: '16px',
    marginBottom: '8px',
    color: '#0000008A',
    marginTop: '0',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 400,
      marginTop: '0',
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  legendContainer: {
    paddingTop: '1.5rem',
    borderTop: '1px solid #D8D8D8',
    width: '100%',
    textAlign: 'left',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    '& p': {
      margin: 0,
      fontSize: '14px',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 400,
      lineHeight: '18px',
      color: '#6A6A6A',
    },
  },
  legendColor: {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    marginRight: '8px',
  },
  select: {
    marginBottom: '1.5rem',
  },
  selectedModelLabel: {
    position: 'absolute',
    top: '-1rem',
    right: '-.5rem',
    fontSize: '12px',
    padding: '6px 10px',
    fontWeight: 400,
    color: '#3268A7',
    border: '1px solid #3268A7',
    borderRadius: '4px',
  },
}))

export default useStyles
