import { faker } from '@faker-js/faker'

export const useMockQuery = () => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const mockedData = {
    effectiveHourlyRate: faker.datatype.number({ min: 1000, max: 3000 }),
    percentageTimeCoaching: faker.datatype.number({ min: 0, max: 100 }),
    numberOfStudents: faker.datatype.number({ min: 100, max: 500 }),
    ytdRevenueInCents: faker.datatype.number({ min: 10000, max: 50000 }),
    ytdProfitInCents: faker.datatype.number({ min: 10000, max: 30000 }),
    monthlyFinancials: {
      ...months.map(month => ({
        month,
        revenueInCents: faker.datatype.number({ min: 1000, max: 3000 }),
        rentPaidToClubInCents: faker.datatype.number({
          min: 1000,
          max: 3000,
        }),
        purchasesFromClubInCents: faker.datatype.number({
          min: 1000,
          max: 3000,
        }),
        otherExpensesInCents: faker.datatype.number({
          min: 1000,
          max: 3000,
        }),
      })),
    },
    ytdNewStudents: faker.datatype.number({ min: 0, max: 50 }),
    currentContacts: faker.datatype.number({ min: 20, max: 100 }),
    contactConversion: parseFloat(faker.datatype.number({ min: 30, max: 70 })),
    potentialContactValueInCents: faker.datatype.number({
      min: 500,
      max: 2000,
    }),
    contactValueInCents: faker.datatype.number({ min: 1000, max: 3000 }),
    totalNewContactsNeeded: faker.datatype.number({ min: 10, max: 50 }),
  }

  const facilityReportData = {
    directPaymentToFacilityInCents: [
      ...months.map(month => ({
        month,
        value: faker.datatype.number({ min: 1000, max: 3000 }),
      })),
    ],
    newStudents: faker.datatype.number({ min: 10, max: 50 }),
    totalStudents: faker.datatype.number({ min: 100, max: 500 }),
    additionalRevenueToFacility: [
      ...months.map(month => ({
        month,
        agreedModel: faker.datatype.number({ min: 1000, max: 3000 }),
        bvgiModel: faker.datatype.number({ min: 1000, max: 3000 }),
        pgaSuggestedModel: faker.datatype.number({ min: 1000, max: 3000 }),
      })),
    ],
    totalRevenueToClub: faker.datatype.number({
      min: 10000,
      max: 30000,
    }),
    participantsTurnedMembers: faker.datatype.number({
      min: 100,
      max: 500,
    }),
    hoursWithProfessional: faker.datatype.number({
      min: 1000,
      max: 3000,
    }),
  }

  return {
    data: mockedData,
    facility: facilityReportData,
  }
}
