import gql from 'graphql-tag'

export const GetFacilityConstantsQuery = gql`
  query FacilityConstants {
    facilityConstants {
      avgDrivingRangeSpendInCents
      avgFbSpendInCents
      avgMerchandiseSpendInCents
      avgOtherSpendInCents
      coachId
      facilityId
      id
      membershipCostInCents
      reportId
    }
  }
`

export const CreateReportMutation = gql`
  mutation CreateReport($input: ReportInput!) {
    createReport(input: $input) {
      code
      message
      success
      report {
        id
        name
        facilityId
        coachId
      }
    }
  }
`

export const UpsertFacilityConstantMutation = gql`
  mutation UpsertFacilityConstant(
    $input: FacilityConstantInput!
    $facilityId: ID!
    $reportId: ID!
  ) {
    upsertFacilityConstant(
      facilityId: $facilityId
      reportId: $reportId
      input: $input
    ) {
      code
      message
      success
      facilityConstant {
        coachId
        facilityId
        id
        reportId
      }
    }
  }
`

export const UpsertMonthlyInputMutation = gql`
  mutation UpsertMonthlyMetric(
    $input: MonthlyMetricInput!
    $facilityId: ID!
    $reportId: ID!
    $month: Int!
  ) {
    upsertMonthlyMetric(
      facilityId: $facilityId
      reportId: $reportId
      month: $month
      input: $input
    ) {
      code
      message
      success
    }
  }
`

export const GetFacilityQuery = gql`
  query Facility($facilityId: ID!) {
    facility(facilityId: $facilityId) {
      id
    }
  }
`

export const UpsertBusinessGoalMutation = gql`
  mutation UpsertBusinessGoal($reportId: ID!, $input: BusinessGoalInput!) {
    upsertBusinessGoal(reportId: $reportId, input: $input) {
      code
      message
      success
    }
  }
`
