export const centsToDollars = amountInCents =>
  amountInCents != null ? +(amountInCents / 100).toFixed(2) : null

export const centsToDollarsFormatted = amountInCents =>
  amountInCents != null
    ? '$' +
    centsToDollars(amountInCents).toLocaleString('en-US', {
      minimumFractionDigits: 2,
    })
    : null

export const dollarsToCents = amountInDollars => {
  let amountFormatted
  if (typeof amountInDollars === 'string') {
    amountFormatted = amountInDollars?.replace(',', '')
  } else {
    amountFormatted = amountInDollars
  }
  return amountFormatted != null ? Math.round(amountFormatted * 100) : null
}

export const isValidDollarsAmount = amount =>
  /^\d+(?:\.\d{1,2})?$/.test(String(amount))

export const estimateCoachingCenterSalesTaxInCents = amountInCents => {
  const FRISCO_TAX_RATE = 0.0825
  if (amountInCents == null) return null

  return Math.round(amountInCents * FRISCO_TAX_RATE)
}

export const formatMoneyFromCents = value => {
  const formated = new Intl.NumberFormat('en-US', {
    style: 'currency',
    maximumFractionDigits: 2,
    roundingMode: 'floor',
    currency: 'USD',
  }).format(value / 100)

  return `${formated.replace(/^([A-Z]{3})\s*(.+)$/, '$2 $1')}`
}
