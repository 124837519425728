import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  reportCardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    padding: '1rem',
    cursor: 'pointer',
  },
  reportCardContentContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',
  },
  reportCardLogo: {
    backgroundColor: '#F4F6F8',
    borderRadius: '50%',
    padding: '1rem',
    '& svg': {
      color: '#00234B',
    },
  },
  reportCardContent: {
    display: 'flex',
    flexDirection: 'column',
    '& h4': {
      fontSize: '1rem',
      fontWeight: 500,
      margin: '0',
      lineHeight: '28px',
    },
    '& p': {
      fontSize: '14px',
      margin: '0',
      color: '#0000008A',
      lineHeight: '22px',
    },
  },
  reportCardActions: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& svg': {
      color: '#0000008A',
    },
  },
}))

export default useStyles
