import React from 'react'
import get from 'lodash/get'
import LinesEllipsis from 'react-lines-ellipsis'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { MY_PGA_COM_HOST } from 'env'
import Dialog from 'components/dialog'
import CopyTextButton from '../copy-text-button'
import IconButton from '@material-ui/core/IconButton'
import SocialSharePopover from '../social-share-popover'
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined'
import ShareBookingUrlAndAccessCodeSection from './share-booking-url-and-access-code-section'
import { useAuth } from 'lib/auth'
import { useHistory } from 'react-router-dom'
import { OnboardingSuccessDialog } from 'components/group-events/create-group-event-form-wizard/steps/success'

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    padding: `${theme.spacing(2)}px`,

    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`,
    },
    '& h4': {
      fontWeight: 700,
    },
    '& p': {
      color: theme.palette.text.secondary,
    },
  },
  dialogContent: {
    padding: 0,
  },
  dialogActions: {
    marginTop: '16px',
    flexDirection: 'column',
    [theme.breakpoints.only('xs')]: {
      padding: '8px',
    },
  },
  textWrapper: {
    margin: `0 ${theme.spacing(8)}px`,
    [theme.breakpoints.only('xs')]: {
      margin: 0,
    },
  },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: theme.spacing(11),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      marginLeft: 0,
    },
    '& h6': {
      marginRight: theme.spacing(2),
      overflowWrap: 'anywhere',
    },
  },
  dialogIcon: {
    borderRadius: '50%',
    background: 'rgba(0,25,75,.08)',
    padding: '1rem',
    color: theme.palette.primary.main,
    width: '83px',
    height: '83px',
    boxSizing: 'border-box',
    margin: '12px auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fontSize: '36px',
    },
  },
}))

const formatBookingTypeUrl = lessonType => {
  if (!lessonType) {
    return null
  }

  const coachId = lessonType?.coach?.externalId
  const coachSlug = get(lessonType, 'coach.coachProfile.slug')
  const lessonTypeId = lessonType.id
  const locationId = get(lessonType, 'facilities[0].id')
  const isLessonPackOffering = lessonType?.lessonPackOfferings?.length > 0

  let bookingUrl = `${MY_PGA_COM_HOST}/coach/${coachSlug ||
    coachId}/schedule?lessonTypeId=${lessonTypeId}&locationId=${locationId}`

  if (isLessonPackOffering) {
    bookingUrl += '&isLessonPacks=true'
  }

  return bookingUrl
}

const BookingTypeFormSuccessDialog = ({
  isOpen,
  setIsOpen,
  onClose,
  onCreateAnother,
  lessonType,
  showOnboardingModal,
}) => {
  const classes = useStyles()
  const { user } = useAuth()
  const history = useHistory()
  const bookingTypeUrl = formatBookingTypeUrl(lessonType)
  const accessCode = lessonType?.accessCode
  const description = accessCode
    ? ' Next, share the link with your students so they can register for this offering. Be sure to include the access code if you added one!'
    : ' Your next step is to share your schedule link below with your students.'

  return showOnboardingModal ? (
    <OnboardingSuccessDialog isOpen={isOpen} setIsOpen={setIsOpen} />
  ) : (
    <Dialog isOpen={isOpen} maxWidth="sm">
      {() => (
        <Box className={classes.dialogContainer}>
          <DialogTitle>
            <Typography variant="h4" align="center">
              Great Work!
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Box className={classes.textWrapper}>
              <Typography variant="body1" align="center">
                You just created a new booking type, well done!
                {bookingTypeUrl && description}
              </Typography>
            </Box>
            {accessCode ? (
              <ShareBookingUrlAndAccessCodeSection
                url={bookingTypeUrl}
                accessCode={accessCode}
              />
            ) : (
              <Box py={2} className={classes.linkWrapper}>
                <Typography variant="subtitle1">
                  <LinesEllipsis
                    text={bookingTypeUrl}
                    maxLine="1"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                </Typography>
                <CopyTextButton component={IconButton} value={bookingTypeUrl}>
                  <FileCopyOutlinedIcon color="primary" />
                </CopyTextButton>
                <SocialSharePopover
                  url={bookingTypeUrl}
                  trigger={
                    <IconButton>
                      <ShareOutlinedIcon color="primary" />
                    </IconButton>
                  }
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginBottom: '16px' }}
              onClick={onCreateAnother}
            >
              Create another booking type
            </Button>
            <Button
              color="primary"
              onClick={() => {
                onClose()
                user.onboarding && history.push('/pga-coach/set-up-schedule')
              }}
            >
              I'm Finished
            </Button>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  )
}

export default BookingTypeFormSuccessDialog
