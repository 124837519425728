import React from 'react'
import {
  Typography,
  TextField,
  Box,
  Grid,
} from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { formatCurrencyInput, formatCurrencyOnBlur } from 'utils/number'

const FacilityMetrics = ({ report }) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  const formValues = watch()

  const handleCurrencyChange = (name) => (event) => {
    const formattedValue = formatCurrencyInput(event.target.value)
    setValue(name, formattedValue, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const handleBlur = (name) => (event) => {
    if (event.target.value) {
      const formattedValue = formatCurrencyOnBlur(event.target.value)
      setValue(name, formattedValue, {
        shouldValidate: true,
        shouldDirty: true,
      })
    }
  }

  return (
    <div>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontSize: '34px', lineHeight: '42px', fontWeight: 500 }}
      >
        Facility Metrics
      </Typography>

      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ mb: 3, fontSize: '16px', lineHeight: '24px', color: '#0000008A' }}
      >
        Enter your facility metrics, averages should be calculated per day and per student.
      </Typography>

      <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <TextField
          {...register('costOfMembership')}
          label="Cost of Membership"
          helperText={errors.costOfMembership?.message || 'Monthly dues, initiation, etc.'}
          onChange={handleCurrencyChange('costOfMembership')}
          onBlur={handleBlur('costOfMembership')}
          value={formValues.costOfMembership || ''}
          InputProps={{
            startAdornment: <span>$</span>,
          }}
          error={!!errors.costOfMembership}
          fullWidth
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register('avgFoodAndBevSpend')}
              label="Avg Food and Bev Spend"
              value={formValues.avgFoodAndBevSpend || ''}
              onChange={handleCurrencyChange('avgFoodAndBevSpend')}
              onBlur={handleBlur('avgFoodAndBevSpend')}
              InputProps={{
                startAdornment: <span>$</span>,
              }}
              error={!!errors.avgFoodAndBevSpend}
              helperText={errors.avgFoodAndBevSpend?.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register('avgMerchSpend')}
              label="Avg Merch Spend"
              value={formValues.avgMerchSpend || ''}
              onChange={handleCurrencyChange('avgMerchSpend')}
              onBlur={handleBlur('avgMerchSpend')}
              InputProps={{
                startAdornment: <span>$</span>,
              }}
              error={!!errors.avgMerchSpend}
              helperText={errors.avgMerchSpend?.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register('avgDrivingRangeSpend')}
              label="Avg Driving Range Spend"
              value={formValues.avgDrivingRangeSpend || ''}
              onChange={handleCurrencyChange('avgDrivingRangeSpend')}
              onBlur={handleBlur('avgDrivingRangeSpend')}
              InputProps={{
                startAdornment: <span>$</span>,
              }}
              error={!!errors.avgDrivingRangeSpend}
              helperText={errors.avgDrivingRangeSpend?.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register('avgOtherSpend')}
              label="Avg Other Spend"
              value={formValues.avgOtherSpend || ''}
              onChange={handleCurrencyChange('avgOtherSpend')}
              onBlur={handleBlur('avgOtherSpend')}
              InputProps={{
                startAdornment: <span>$</span>,
              }}
              error={!!errors.avgOtherSpend}
              helperText={errors.avgOtherSpend?.message}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default FacilityMetrics
