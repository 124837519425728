import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  card: {
    border: '1px solid #E0E0E0',
    padding: '1.5rem',
    textAlign: 'left',
    borderRadius: '12px',
    fontFamily: 'Roboto',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: 0,
  },
  value: {
    fontSize: '30px',
    marginTop: 0,
    fontWeight: 500,
    marginBottom: '12px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },
  description: {
    fontWeight: 400,
    fontSize: '16px',
    marginBottom: '8px',
    color: '#0000008A',
    marginTop: '0',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 500,
      marginTop: '0',
    },
  },
}))

export default useStyles
