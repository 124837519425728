import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { ThemeProvider } from '@material-ui/core/styles'
import AccountsBenefitModal from 'components/account-benefits-modal'
import { useStyles } from './styles'
import PaymentInformation from '../components/payment-information'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { validationSchema } from '../utils'
import { withStripeElements } from 'lib/stripe'
import qs from 'query-string'
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { gtmEvent } from 'lib/gtm'
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/client'
import { PurchaseLessonPackQuery } from './query'
import { REDEEM_LESSON_CREDIT_QUERY } from 'pages/schedule/ReviewBookingLessonPacks/query'
import CircularProgress from '@material-ui/core/CircularProgress'
import SelectLessonPack from '../components/SelectLessonPack'
import { useLocation } from 'react-router-dom'
import VerifyAccessCode from 'components/verify-access-code'
import BackButton from 'components/back-button'
import themes, { extendTheme } from 'themes'
import CoachLessonHeader from '../components/CoachLessonHeader'
import { ContainerRoot, WaiverContainer } from '../components/styles'
import {
  COACHING_CENTER_SLUGS,
  COACHING_CENTER_HOLIDAY_LESSON_TYPE_IDS,
  FRISCO_COACHING_CENTER_FACILITY_ID,
} from 'utils/constants'
import { CoachingCenterWaiver } from 'components/waivers'
import { useCreditCardErrors } from 'lib/hooks'
import { flags, useFeatureFlags } from 'lib/feature-flags'
import TermsAndConditions from '../components/TermsAndConditions'
import { redirectToLogin, redirectToSignup } from 'lib/auth'
import { get } from 'lodash'
import MarketingOptIn from 'components/marketing-opt-in/marketing-opt-in'

const BuyLessonPackLayout = ({
  previousUrl,
  lessonInfo,
  user,
  setIsComplete,
  setLastDigits,
  setCardBrand,
  setSelectedLessonPackId,
  selectedLessonPackId,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [isChecked, setIsChecked] = useState(false)
  const [purchaseLessonPack, { loading }] = useMutation(PurchaseLessonPackQuery)
  const [redeemLessonCredit, { loadingRedeem }] = useMutation(
    REDEEM_LESSON_CREDIT_QUERY,
  )
  const { coach, requiresAccessCode } = lessonInfo

  const ccAffiliatedLessonPack = previousUrl.includes(
    'coaching-center' || COACHING_CENTER_SLUGS,
  )
  const coachBooksOnBehalfOfStudent = user?.externalId === coach?.externalId
  const shouldShowWaiver =
    COACHING_CENTER_SLUGS.includes(lessonInfo?.academy?.slug) &&
    !coachBooksOnBehalfOfStudent
  const [showWaiver, setShowWaiver] = useState(false)
  const userIsNotOptedIn = !user?.marketingOptInAt

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [showWaiver])

  const form = useForm({
    resolver: yupResolver(validationSchema(true, shouldShowWaiver)),
    mode: 'onTouched',
    shouldFocusError: true,
  })
  const {
    register,
    formState: { isValid },
  } = form
  const stripe = useStripe()
  const elements = useElements()
  const creditCardErrorMessage = useCreditCardErrors(
    elements,
    true,
    !shouldShowWaiver,
  )
  const location = useLocation()

  const {
    startDateTime,
    lessonTypeId,
    studentId,
    facilityId,
    locationId,
  } = qs.parse(location.search)

  const [verifiedAccessCode, setVerifiedAccessCode] = useState(
    !requiresAccessCode || location?.state?.verifiedAccessCode,
  )
  const [isSubmitting, setIsSubmitting] = useState(false)

  const isHolidayPack =
    COACHING_CENTER_HOLIDAY_LESSON_TYPE_IDS.includes(lessonTypeId) &&
    FRISCO_COACHING_CENTER_FACILITY_ID === locationId
  const [useMarketingOptIn] = useFeatureFlags([
    flags.FLAG_FEAT_MARKETING_OPT_IN_LESSON_PACKS,
  ])
  const showTermsAndConditions = isHolidayPack
  const [marketingOptInChecked, setMarketingOptInChecked] = useState(false)

  const selectedLessonPack = lessonInfo?.lessonPacks.filter(
    lp => lp.id === selectedLessonPackId,
  )
  const eventObject = {
    event: 'complete-lesson-pack-purchase',
    title: lessonInfo?.title,
    category: lessonInfo?.category,
    facility: get(lessonInfo?.facilities, ['0', 'name']),
    location: `${get(lessonInfo?.facilities, ['0', 'city'])}, ${get(
      lessonInfo?.facilities,
      ['0', 'state'],
    )}`,
    price: `${get(selectedLessonPack, ['0', 'priceInCents']) / 100}`,
  }

  const canSubmit = showTermsAndConditions
    ? isValid && isChecked && !isSubmitting && selectedLessonPackId
    : isValid && !isSubmitting && selectedLessonPackId

  const handleButtonClick = async () => {
    if (shouldShowWaiver) {
      const isValid = await form.trigger(['payment'])
      if (isValid && creditCardErrorMessage) {
        enqueueSnackbar(creditCardErrorMessage, {
          variant: 'error',
        })
      }
      if (isValid && !creditCardErrorMessage) {
        setShowWaiver(true)
      }
    } else {
      await form.handleSubmit(onSubmit)()
    }
  }

  const onSubmit = async formValues => {
    setIsSubmitting(true)
    const marketingOptInAt = marketingOptInChecked
      ? new Date().toISOString()
      : null
    const { error: stripeError, token: stripeToken } = await stripe.createToken(
      elements.getElement(CardNumberElement),
      {
        name: formValues.payment.name,
        address_country: 'US',
      },
    )

    if (stripeError) {
      setIsSubmitting(false)
      enqueueSnackbar(stripeError.message, {
        variant: 'error',
      })
      return Promise.resolve()
    }
    return purchaseLessonPack({
      variables: {
        lessonPackId: selectedLessonPackId,
        stripeToken: stripeToken?.id,
        marketingOptInAt: marketingOptInAt,
      },
    })
      .then(({ data }) => {
        const { success, message } = data.purchaseLessonPack

        if (success) {
          enqueueSnackbar(message, {
            variant: 'success',
          })
          gtmEvent(eventObject)
          // Remove the below event in the future.
          gtmEvent({
            event: 'formSubmit',
            formCategory: 'purchase-lesson-pack',
            formAction: 'clicked-complete-purchase-button',
          })
          if (useMarketingOptIn && marketingOptInChecked) {
            gtmEvent({
              event: 'marketing-opt-in',
              source: 'lesson_pack_purchase',
            })
          }
          setIsComplete(true)
          setLastDigits(stripeToken.card.last4)
          setCardBrand(stripeToken.card.brand)
        } else {
          setIsSubmitting(false)
          enqueueSnackbar(message, {
            variant: 'error',
          })
        }
        return { success: success }
      })
      .then(({ success }) => {
        if (!ccAffiliatedLessonPack || !success) return Promise.resolve()
        return redeemLessonCredit({
          variables: {
            lessonTypeId: lessonTypeId,
            locationId: facilityId,
            startDateTime: startDateTime,
            studentId: studentId,
          },
          skip: !ccAffiliatedLessonPack,
        }).then(({ data }) => {
          const { success, message } = data.redeemLessonCredit
          if (success) {
            enqueueSnackbar(message, {
              variant: 'success',
            })
          } else {
            setIsSubmitting(false)
            enqueueSnackbar(message, {
              variant: 'error',
            })
          }
        })
      })
      .catch(error => {
        setIsSubmitting(false)
        window.rg4js &&
          window.rg4js('send', {
            error: error,
            tags: ['lesson-pack', 'form-submit'],
          })
        enqueueSnackbar('An error has occurred. Please try again later.', {
          variant: 'error',
        })
      })
      .finally(() => {
        setShowWaiver(false)
      })
  }

  if (loading || loadingRedeem) {
    return (
      <ContainerRoot className={classes.root}>
        <div className={classes.loadingStyles}>
          <CircularProgress color="primary" />
        </div>
      </ContainerRoot>
    )
  }

  return (
    <>
      <WaiverContainer showWaiver={showWaiver}>
        <CoachingCenterWaiver
          form={form}
          showWaiver={showWaiver}
          setShowWaiver={setShowWaiver}
          onSubmit={onSubmit}
          withBackButton={true}
        />
      </WaiverContainer>
      <ContainerRoot
        className={classes.rootRefresh}
        style={{ display: showWaiver ? 'none' : 'block' }}
      >
        <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
          <BackButton previousUrl={previousUrl} />
          <CoachLessonHeader lessonType={lessonInfo} coach={coach} />
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid container spacing={2}>
            <Grid container item md={6} xs={12} className={classes.textWrapped}>
              <Box display={user ? 'grid' : 'none'}>
                <SelectLessonPack
                  lessonInfo={lessonInfo}
                  selectedLessonPackId={selectedLessonPackId}
                  setSelectedLessonPackId={setSelectedLessonPackId}
                />
                <FormProvider {...form}>
                  <form>
                    <Grid item md={9} sm={10} xs={12}>
                      <Box mb={4}>
                        <PaymentInformation />
                      </Box>
                      {useMarketingOptIn && userIsNotOptedIn && (
                        <MarketingOptIn
                          checked={marketingOptInChecked}
                          onChange={setMarketingOptInChecked}
                          useMarketingOptIn={'callout'}
                        />
                      )}
                      {showTermsAndConditions && (
                        <TermsAndConditions
                          isChecked={isChecked}
                          setIsChecked={setIsChecked}
                          register={register}
                        />
                      )}
                    </Grid>
                    <Grid item md={5}>
                      <Box mb={4} textAlign="left">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleButtonClick}
                          className={classes.buttonStyles}
                          data-testid="complete-booking-button"
                          disabled={!canSubmit}
                        >
                          {shouldShowWaiver ? 'Continue' : 'Complete Purchase'}
                        </Button>
                      </Box>
                    </Grid>
                  </form>
                </FormProvider>
              </Box>
              <Box display={user ? 'none' : 'grid'} mb={5}>
                <Typography variant="h5">
                  Sign in or create an account
                </Typography>
                <Typography variant="body" className={classes.warningBox}>
                  You must have a MyPGA account to book this session.{' '}
                  <span
                    onClick={() => setIsOpen(true)}
                    className={classes.learnMoreText}
                  >
                    Learn more
                  </span>
                </Typography>
                <Box display="flex">
                  <Grid container spacing={2}>
                    <Grid item xs={7} md={5}>
                      <Button
                        onClick={() => redirectToSignup('consumer')}
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth={true}
                        style={{ textAlign: 'center' }}
                      >
                        Create an Account
                      </Button>
                    </Grid>
                    <Grid item xs={4} md={5}>
                      <Button
                        onClick={() => redirectToLogin('consumer')}
                        variant="outlined"
                        color="primary"
                        size="large"
                        fullWidth={true}
                        style={{ textAlign: 'center' }}
                      >
                        Sign In
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <AccountsBenefitModal isOpen={isOpen} setIsOpen={setIsOpen} />
          <VerifyAccessCode
            lessonType={lessonInfo}
            isOpen={!verifiedAccessCode}
            setIsOpen={setVerifiedAccessCode}
            coachName={coach.name}
            setVerifiedAccessCode={setVerifiedAccessCode}
          />
        </ThemeProvider>
      </ContainerRoot>
    </>
  )
}

export default withStripeElements(BuyLessonPackLayout)
