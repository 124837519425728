import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import useStyles from './styles'

const InfoTextContainer = ({ children, style }) => {
  const classes = useStyles()
  return (
    <div className={classes.footer} style={style}>
      <div className={classes.footerIcon}>
        <InfoOutlinedIcon />
      </div>
      <p className={classes.footerText}>
        {children}
      </p>
    </div>
  )
}

export default InfoTextContainer
