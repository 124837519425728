import React, { useState } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { useMutation, useQuery, gql } from '@apollo/client'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'
import CircularProgress from '@material-ui/core/CircularProgress'
import { DateTime } from 'luxon'
import RedeemIcon from '@mui/icons-material/Redeem'
import { IconCheckMark } from 'components/atoms/icons'
import { PageHeader } from 'components/page-header'
import { useStyles } from './styles'

const GET_LESSON_PACK_AND_INVITATION = gql`
  query GetLessonPackAndInvitation($id: ID!, $token: ID!) {
    lessonPacks(id: $id) {
      id
      remainingCredits
      expiresAt
      lessonPackOffering {
        priceInCents
      }
      lessonType {
        title
      }
    }
    invitation(id: $token) {
      id
      status
      invitationRoles {
        id
      }
    }
  }
`

const ACCEPT_LESSON_CREDIT = gql`
  mutation AcceptLessonCredit($id: ID!) {
    acceptInvitation(id: $id) {
      success
      message
    }
  }
`

const AcceptLessonCredit = () => {
  const classes = useStyles()
  const history = useHistory()
  const { lessonPackId } = useParams()
  const { search } = useLocation()
  const token = new URLSearchParams(search).get('token')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  const { loading, error: queryError, data } = useQuery(
    GET_LESSON_PACK_AND_INVITATION,
    {
      variables: { id: lessonPackId, token },
    },
  )

  const [acceptCredit, { loading: acceptLoading }] = useMutation(
    ACCEPT_LESSON_CREDIT,
    {
      onCompleted: data => {
        if (data.acceptInvitation.success) {
          setSuccess(true)
        } else {
          setError(data.acceptInvitation.message)
        }
      },
    },
  )

  if (loading) return <CircularProgress />
  if (queryError) {
    return <Alert severity="error">Error loading gift details</Alert>
  }

  const lessonPack = data?.lessonPacks?.[0]
  const { invitation } = data

  if (!lessonPack || !invitation) {
    return <Alert severity="error">Invalid gift link</Alert>
  }

  if (invitation.status.toLowerCase() !== 'pending') {
    return (
      <Alert severity="info">
        This gift has already been {invitation.status.toLowerCase()}
      </Alert>
    )
  }

  const expiresAt = DateTime.fromISO(lessonPack.expiresAt).toFormat(
    'MMM d, yyyy',
  )

  const handleAccept = () => {
    setError('')
    acceptCredit({ variables: { id: token } })
  }

  const handleHome = () => {
    history.push('/club')
  }

  return (
    <>
      <PageHeader title="Accept Lesson Pack" />
      <Box className={classes.root}>
        <Box className={classes.content}>
          <Box className={classes.redeemIcon}>
            <RedeemIcon />
          </Box>

          <Typography variant="h6" gutterBottom>
            Accept Your Lesson Pack!
          </Typography>

          <Typography variant="body1" paragraph>
            You have been gifted {lessonPack.lessonType.title}.
          </Typography>

          <Typography variant="body1" paragraph>
            By accepting this gift, you agree to the terms and conditions of the
            gift. The lessons will be added to your account and will be
            available for you to schedule.
          </Typography>

          <Typography variant="body1" paragraph>
            Please note that all lesson credits must be redeemed by {expiresAt}{' '}
            or the remaining credits will be forfeited from your account.
          </Typography>

          {error && (
            <Alert severity="error" className={classes.alert}>
              {error}
            </Alert>
          )}

          <Box className={classes.actions}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAccept}
              disabled={acceptLoading || success}
              className={classes.acceptButton}
            >
              <IconCheckMark />
              {acceptLoading ? (
                <CircularProgress size={24} />
              ) : success ? (
                'Accepted'
              ) : (
                'Accept Lesson Pack'
              )}
            </Button>
            <Button
              color="primary"
              onClick={handleHome}
              className={classes.homeButton}
            >
              Home
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default AcceptLessonCredit
