import { useTheme } from '@material-ui/styles'
import { useState } from 'react'
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import GolfCourseIcon from '@material-ui/icons/GolfCourse'
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined'
import BookingDateOrDuration from '../components/BookingDateOrDuration'
import { SavingsIcon } from 'components/icons/refreshed-icons'
import { formatCurrency } from '../utils'
import LessonPackDetails from './lesson-pack-details'

const SummaryDetails = ({ lessonType, coach, classes, selectedLessonPack }) => {
  const theme = useTheme()
  const [showSeeMore, setShowSeeMore] = useState(true)
  const { title, durationInMinutes, priceInCents, facilities } = lessonType
  const { name, coachProfile } = coach
  const initials = name
    .split(' ')
    .map(name => name[0])
    .join('')

  return (
    <>
      <Box display="flex" flexDirection="column" width="100%" my={6}>
        <Typography variant="h4">Details</Typography>
        <Box display="flex" alignItems="center" my={2}>
          {coachProfile.profilePhoto ? (
            <Avatar
              className={classes.mobileAvatar}
              alt={name}
              src={coachProfile.profilePhoto}
            >
              {name}
            </Avatar>
          ) : (
            <Avatar className={classes.mobileAvatar}>{initials}</Avatar>
          )}
          <Typography variant="h5" className={classes.coachCardName}>
            {name}
          </Typography>
        </Box>
        <Typography variant="h4">{title}</Typography>
        {!showSeeMore && (
          <List dense={true}>
            <Typography
              variant="subtitle1"
              onClick={() => setShowSeeMore(true)}
              className={classes.seeMoreToggle}
            >
              Read more
            </Typography>
          </List>
        )}
        {showSeeMore && (
          <>
            <List dense={true}>
              <ListItem disableGutters={true}>
                <ListItemIcon>
                  <GolfCourseIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.attributes }}
                  primary={facilities[0].name}
                />
              </ListItem>
              {facilities[0].city && facilities[0].state && (
                <ListItem disableGutters={true}>
                  <ListItemIcon>
                    <PlaceOutlinedIcon className={classes.icons} />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.attributes }}
                    primary={`${facilities[0].city}, ${facilities[0].state}`}
                  />
                </ListItem>
              )}
              {selectedLessonPack ? (
                <LessonPackDetails
                  classes={classes}
                  selectedLessonPack={selectedLessonPack}
                  timezone={facilities[0].timezone}
                />
              ) : (
                <>
                  <BookingDateOrDuration
                    durationInMinutes={durationInMinutes}
                    timezone={facilities[0].timezone}
                    classes={classes}
                  />
                  <ListItem disableGutters={true}>
                    <ListItemIcon>
                      <SavingsIcon
                        size={24}
                        className={classes.icons}
                        color={theme.palette.secondary.dark}
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.attributes }}
                      primary={formatCurrency(priceInCents / 100)}
                    />
                  </ListItem>
                </>
              )}
            </List>
            <Typography
              variant="subtitle1"
              onClick={() => setShowSeeMore(false)}
              className={classes.seeMoreToggle}
            >
              Read less
            </Typography>
          </>
        )}
      </Box>
    </>
  )
}

export default SummaryDetails
