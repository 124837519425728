import React from 'react'
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import AlertWithDivider from 'pages/schedule/components/AlertWithDivider'
import RedeemIcon from '@material-ui/icons/Redeem'

const BookingTypeGifting = ({ giftingEnabled, setGiftingEnabled }) => {
  const handleGiftingEnabled = event => {
    setGiftingEnabled(event.target.value === 'true')
  }

  return (
    <Box mt={3} mb={4}>
      <Typography variant="subtitle1" gutterBottom>
        Gifting
      </Typography>
      <Typography variant="body1" gutterBottom>
        Choose whether you'd like to enable or disable gifting for this lesson
        pack.
      </Typography>
      <AlertWithDivider
        icon={<RedeemIcon />}
        text="Updating this field could impact lesson packs that have already been
          purchased. For more information regarding gifting, please see the link."
        ctaLink="https://support.pga.com/en/articles/6380476-selling-lesson-packs"
      />
      <FormControl>
        <RadioGroup
          aria-labelledby="Booking Type Gifting"
          defaultValue={giftingEnabled.toString()}
          onChange={handleGiftingEnabled}
        >
          <FormControlLabel
            value="true"
            control={<Radio color="primary" />}
            label="Allow Gifting"
          />
          <Typography
            variant="body2"
            gutterBottom
            style={{ marginLeft: '32px' }}
          >
            Buyers can purchase and gift the entire lesson pack to others,
            making it transferable.
          </Typography>
          <FormControlLabel
            value="false"
            control={<Radio color="primary" />}
            label="Don't Allow Gifting"
          />
          <Typography
            variant="body2"
            gutterBottom
            style={{ marginLeft: '32px' }}
          >
            This lesson pack is non-transferable, and only the buyer can use it.
          </Typography>
        </RadioGroup>
      </FormControl>
    </Box>
  )
}

export default BookingTypeGifting
