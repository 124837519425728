import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  gridContainer: {
    padding: '16px',
    maxWidth: '1090px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    [theme.breakpoints.down('sm')]: {
      gap: '16px',
      padding: '0',
    },
  },
  yearSelector: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
  },
  select: {
    width: '10rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0',
    },
  },
  exportButton: {
    marginRight: '0',
    borderColor: '#00234B80',
    color: '#043362',
  },
}))

export default useStyles
