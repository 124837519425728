import { useState, useEffect } from 'react'
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import CoachInsightsStatsCard from 'components/coach-insights-stats-card'
import useStyles from './styles'
import CoachInsightsYTDChart from 'components/coach-insights-ytd-chart'
import CoachInsightsProfitChart from 'components/coach-insights-profit'
import { formatMoneyFromCents } from 'lib/utils/number'
import EmptyPage from 'components/empty-page-state'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'
import { Skeleton } from '@material-ui/lab'
import {
  transformMonthlyFinancials,
  transformYtdRevenue,
  transformYtdExpenses,
  calculateTotalExpenses,
} from './utils'

const MyBusinessInsights = ({ businessInsights, businessInsightsLoading }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const classes = useStyles()
  const [monthlyFinancials, setMonthlyFinancials] = useState([])
  const [ytdRevenue, setYtdRevenue] = useState([])
  const [ytdExpenses, setYtdExpenses] = useState([])
  const [ytdExpensesInCents, setYtdExpensesInCents] = useState(0)

  const currentYear = new Date().getFullYear()
  const [selectedYear, setSelectedYear] = useState(currentYear.toString())

  const handleYearChange = event => {
    setSelectedYear(event.target.value)
  }

  const renderSkeletonCard = () => (
    <div className={classes.skeletonCard}>
      <Skeleton variant="circle" width={40} height={40} />
      <Skeleton variant="text" width="60%" height={40} />
      <Skeleton variant="text" width="40%" />
    </div>
  )

  useEffect(() => {
    if (businessInsights?.monthlyFinancials) {
      setMonthlyFinancials(
        transformMonthlyFinancials(businessInsights.monthlyFinancials),
      )
      setYtdRevenue(transformYtdRevenue(businessInsights.monthlyFinancials))
      setYtdExpenses(transformYtdExpenses(businessInsights.monthlyFinancials))
      setYtdExpensesInCents(
        calculateTotalExpenses(businessInsights.monthlyFinancials),
      )
    }
  }, [businessInsights])

  return (
    <div className={classes.gridContainer}>
      <div className={classes.yearSelector}>
        <div></div>
        <FormControl className={classes.select}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedYear}
            onChange={handleYearChange}
          >
            <MenuItem value="2024">2024</MenuItem>
            <MenuItem value="2023">2023</MenuItem>
            <MenuItem value="2022">2022</MenuItem>
          </Select>
        </FormControl>
      </div>
      {businessInsightsLoading && (
        <Grid container spacing={isMobile ? 2 : 3}>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={6} sm={4} key={index}>
              {renderSkeletonCard()}
            </Grid>
          ))}
          <Grid item sm={12} xs={12}>
            <Skeleton variant="rect" height={300} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Skeleton variant="rect" height={300} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Skeleton variant="rect" height={300} />
          </Grid>
        </Grid>
      )}
      {!businessInsightsLoading && Object.keys(businessInsights)?.length > 0 ? (
        <Grid container spacing={isMobile ? 2 : 3}>
          <Grid item xs={6} sm={3} key="effectiveHourlyRate">
            <CoachInsightsStatsCard
              icon={MonetizationOnOutlinedIcon}
              value={formatMoneyFromCents(
                businessInsights?.effectiveHourlyRate || 'n/a',
              )}
              description={
                isMobile ? 'Avg. Hourly Rate' : 'Effective Hourly Rate'
              }
            />
          </Grid>
          <Grid item xs={6} sm={3} key="percentageTimeCoaching">
            <CoachInsightsStatsCard
              icon={AccessTimeOutlinedIcon}
              value={`${businessInsights?.percentageTimeCoaching || 'n/a'}%`}
              description={
                isMobile ? 'Time Coaching' : 'Percentage Time Coaching'
              }
            />
          </Grid>
          <Grid item xs={6} sm={3} key="numberOfStudents">
            <CoachInsightsStatsCard
              icon={GroupOutlinedIcon}
              value={businessInsights?.numberOfStudents || 'n/a'}
              description={isMobile ? 'Students' : 'Total Students'}
            />
          </Grid>
          <Grid item xs={6} sm={3} key="ytdNewStudents">
            <CoachInsightsStatsCard
              icon={PersonAddAltOutlinedIcon}
              value={businessInsights?.ytdNewStudents || 'n/a'}
              description="New Students"
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <CoachInsightsYTDChart
              barChartData={ytdRevenue}
              title="YTD Revenue"
              value={formatMoneyFromCents(businessInsights?.ytdRevenueInCents)}
            />
          </Grid>
          <Grid item xs={6} sm={3} key="currentContacts">
            <CoachInsightsStatsCard
              icon={PersonOutlinedIcon}
              value={businessInsights?.currentContacts || 'n/a'}
              description={isMobile ? 'Leads' : 'Current Leads'}
            />
          </Grid>
          <Grid item xs={6} sm={3} key="contactConversion">
            <CoachInsightsStatsCard
              icon={PersonAddAltOutlinedIcon}
              value={businessInsights?.contactConversion || 'n/a'}
              description={
                isMobile ? 'Lead Conversion' : 'Lead Conversion Rate'
              }
            />
          </Grid>
          <Grid item xs={6} sm={3} key="contactValueInCents">
            <CoachInsightsStatsCard
              icon={StarBorderOutlinedIcon}
              value={formatMoneyFromCents(
                businessInsights?.contactValueInCents || 'n/a',
              )}
              description={isMobile ? 'Lead Value' : 'Potential Lead Value'}
            />
          </Grid>
          <Grid item xs={6} sm={3} key="totalNewContactsNeeded">
            <CoachInsightsStatsCard
              icon={StarBorderOutlinedIcon}
              value={businessInsights?.totalNewContactsNeeded || 'n/a'}
              description="Contact Goals"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <CoachInsightsProfitChart
              lineChartData={monthlyFinancials}
              title="2024 Profit and Expenses"
              value={formatMoneyFromCents(
                businessInsights?.ytdProfitInCents || 'n/a',
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <CoachInsightsYTDChart
              barChartData={ytdExpenses}
              title="YTD Expenses"
              value={formatMoneyFromCents(ytdExpensesInCents)}
            />
          </Grid>
        </Grid>
      ) : (
        <div
          data-testid="business-insights-empty-state"
          className={classes.gridContainer}
        >
          {!businessInsightsLoading && (
            <EmptyPage
              actionFn={() => {}}
              IconComp={ShowChartIcon}
              title="My Business Insights"
              caption="There is currently no business insights data available for this time period."
              buttonText="Create Report"
              actionFnSecondary={() => {}}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default MyBusinessInsights
