import React from 'react'
import { useFormContext } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import StripeInput from 'components/payments/stripe-input'

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontWeight: 400,
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
}

const PaymentInformation = () => {
  const { register, errors } = useFormContext()

  return (
    <>
      <Box mb={3} mt={4} pt={2}>
        <Typography variant="h4">Enter payment details</Typography>
      </Box>
      <Box mb={4}>
        <Typography variant="body">
          Your credit card information is not being stored in our system.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box mb={2}>
            <TextField
              name="payment.name"
              inputRef={register}
              error={!!errors?.payment?.name}
              helperText={errors?.payment?.name?.message}
              label="Cardholder name"
              placeholder="Cardholder name"
              data-testid="cardholder-name"
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: {
                  autoComplete: 'cc-name',
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mb={2}>
            <TextField
              label="Card Number"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardNumberElement,
                  options: ELEMENT_OPTIONS,
                  autoComplete: 'cc-number',
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box>
            <TextField
              label="Expiration"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardExpiryElement,
                  options: ELEMENT_OPTIONS,
                  autoComplete: 'cc-exp',
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box>
            <TextField
              label="CVC"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardCvcElement,
                  options: ELEMENT_OPTIONS,
                  autoComplete: 'cc-csc',
                },
              }}
            />
          </Box>
          {/* if you're looking for the zip code field, it was removed in this PR: https://github.com/pgahq/coaching-programs-frontend/pull/776 */}
        </Grid>
      </Grid>
    </>
  )
}

export default PaymentInformation
