import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { useParams, Redirect } from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/error'
import useBookingTypeFormData from '../useBookingTypeFormData'
import BookingTypeFormWithLessonPacks from 'components/booking-type-form'

const GET_LESSON_TYPE_BY_ID = gql`
  query GetLessonTypeById($id: ID) {
    lessonTypes(id: $id) {
      id
      title
      category
      description
      confirmationMessage
      durationInMinutes
      priceInCents
      locations {
        id
        name
      }
      coachingCenter: academy {
        id
      }
      paymentAccount {
        id
      }
      startTimeIncrementInMinutes
      status
      lessonPacks {
        quantity
        priceInCents
        expirationDuration
      }
      accessCode
      giftingEnabled
    }
  }
`

const EditBookingTypePage = () => {
  const { bookingTypeId } = useParams()
  const bookingTypeQuery = useQuery(GET_LESSON_TYPE_BY_ID, {
    variables: { id: bookingTypeId },
  })
  const formDataQuery = useBookingTypeFormData()

  const loading = bookingTypeQuery.loading || formDataQuery.loading
  const error = bookingTypeQuery.error || formDataQuery.error

  if (loading) return <LinearProgress color="primary" />
  if (error) return <Error error={error} displayErrorType="page" />

  const bookingType = bookingTypeQuery.data?.lessonTypes?.[0]

  if (!bookingType) {
    return <Redirect to="/404" />
  }

  return (
    <BookingTypeFormWithLessonPacks
      {...formDataQuery.data}
      bookingType={bookingType}
    />
  )
}

export default EditBookingTypePage
