import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Controller, useFormContext } from 'react-hook-form'
import { socialsList } from '../constants'
import TextField from '@material-ui/core/TextField'
import { get } from 'lodash'
import InputAdornment from '@material-ui/core/InputAdornment'

const Social = ({ formFields }) => {
  const { control, errors, setValue } = useFormContext()
  const [socialOptions, setSocialOptions] = useState(null)

  useEffect(() => {
    const socialData = formFields?.socialJsonData || []
    const initialValues = socialsList.map(item => {
      const currentValue = socialData.find(social => social.type === item.value)
      if (currentValue?.reference.includes('twitter.com')) {
        currentValue.reference = currentValue.reference.replace(
          'twitter.com',
          'x.com',
        )
      }

      return {
        reference: currentValue?.reference || null,
        type: item.value,
        preferred: true,
      }
    })
    setSocialOptions(initialValues)
  }, [formFields])

  const handleSocialChange = (e, social, i) => {
    const updatedSocial = socialOptions.map((item, iter) => {
      if (iter === i) {
        return {
          ...item,
          reference:
            e.target.value !== '' ? `${social.fullURL}${e.target.value}` : null,
        }
      }
      return item
    })
    setSocialOptions(updatedSocial)
    setValue('socialJsonData', updatedSocial, {
      shouldDirty: true,
      shouldValidate: true,
    })
  }

  const defineCorrespondingValue = (social, value) => {
    const result = value.find(item => item.type === social.value)
    return result || { reference: null, type: social.value, preferred: true }
  }

  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Social
      </Typography>
      <Box mt={2}>
        <Controller
          name="socialJsonData"
          control={control}
          render={({ onChange, value }) =>
            socialsList.map((social, i) => {
              const correspondingValue = defineCorrespondingValue(social, value)
              const shortenURL =
                correspondingValue &&
                correspondingValue.reference &&
                correspondingValue.reference.replace(social.fullURL, '')
              return (
                <TextField
                  name={`socialJsonData[${i}].name`}
                  key={social.value}
                  label={social.label}
                  type="text"
                  value={shortenURL}
                  variant="standard"
                  className={`text-input ${get(errors, [
                    'socialJsonData',
                    i,
                    'reference',
                  ]) && ' error'}`}
                  error={!!get(errors, ['socialJsonData', i, 'reference'])}
                  helperText={get(errors, [
                    'socialJsonData',
                    i,
                    'reference',
                    'message',
                  ])}
                  onChange={e => {
                    onChange(e)
                    handleSocialChange(e, social, i)
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className="input-adornment"
                        style={{ marginRight: 0 }}
                      >
                        {social.placeholder}
                      </InputAdornment>
                    ),
                  }}
                />
              )
            })
          }
        />
      </Box>
    </>
  )
}

export default Social
