export const formatCurrencyInput = value => {
  const cleanValue = value.replace(/[^\d.]/g, '')

  const parts = cleanValue.split('.')
  const number =
    parts.length > 1 ? `${parts[0]}.${parts[1].slice(0, 2)}` : parts[0]

  const normalizedNumber = value.startsWith('.') ? `0${number}` : number

  const hasDecimal = normalizedNumber.includes('.')
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: hasDecimal ? 2 : 0,
    useGrouping: false,
  }).format(parseFloat(normalizedNumber || 0) || 0)
}

export const formatCurrencyOnBlur = value => {
  if (!value) return value
  const number = parseFloat(value.replace(/,/g, ''))
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number || 0)
}

export const convertToCents = value => {
  if (!value) return 0
  const number = parseFloat(value.toString().replace(/,/g, ''))
  return Math.round(number * 100)
}
