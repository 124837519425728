import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useRouteMatch } from 'react-router-dom'
import { PageHeader } from 'components/page-header'
import GET_LESSON_CREDIT_BALANCE from './query'
import LessonPacksDisplay from './lesson-packs-display'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/error'
import LessonPackCard from './lesson-pack-card'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './styles'
import GiftLessonPackModal from './gift'

const LessonPacksPageData = () => {
  const classes = useStyles()
  const { data, loading, error } = useQuery(GET_LESSON_CREDIT_BALANCE)
  const [giftingLessonPackId, setGiftingLessonPackId] = useState(null)

  if (loading) return <LinearProgress color="primary" />
  if (error) return <Error error={error} />

  const lessonCreditBalances = data?.currentUser?.attributes?.lessonPacks || []
  const giftedLessonCreditBalances =
    data?.currentUser?.attributes?.giftedLessonCreditBalances || []

  const activeLessonPacks = lessonCreditBalances.filter(
    lp =>
      lp.remainingCredits > 0 &&
      (lp.expiresAt === null || Date.parse(lp.expiresAt) > Date.now()),
  )

  const usedOrExpiredLessonPacks = lessonCreditBalances.filter(
    lp => lp.remainingCredits === 0 || Date.parse(lp.expiresAt) < Date.now(),
  )

  const handleGiftClick = lessonPackId => {
    setGiftingLessonPackId(lessonPackId)
  }

  const handleGiftClose = () => {
    setGiftingLessonPackId(null)
  }

  return (
    <>
      <LessonPacksDisplay
        activeLessonPacks={activeLessonPacks}
        usedOrExpiredLessonPacks={usedOrExpiredLessonPacks}
        onGiftClick={handleGiftClick}
      />

      <Box className={classes.root}>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            {giftedLessonCreditBalances.length > 0 && (
              <Box mt={4}>
                <Typography variant="h6" gutterBottom>
                  Gifted Lesson Credits
                </Typography>
                {giftedLessonCreditBalances.map((creditBalance, index) => (
                  <Box key={index} mb={2}>
                    <LessonPackCard
                      totalCredits={creditBalance.quantity}
                      remainingCredits={creditBalance.quantity}
                      expiresAt={creditBalance.nextExpirationDate}
                      lessonType={{
                        id: creditBalance.lessonType.id,
                        title: creditBalance.lessonType.title,
                        locations: creditBalance.lessonType.locations,
                        coach: {
                          name: creditBalance.lessonType.coach.name,
                          bookingUrl: creditBalance.lessonType.coach.bookingUrl,
                        },
                        giftingEnabled: false,
                      }}
                      lessonCredits={[]}
                      isUsedOrExpired={false}
                      onGiftClick={() => {}}
                    />
                  </Box>
                ))}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>

      <GiftLessonPackModal
        open={!!giftingLessonPackId}
        onClose={handleGiftClose}
        lessonPack={lessonCreditBalances.find(
          lp => lp.id === giftingLessonPackId,
        )}
      />
    </>
  )
}

const LessonPacksPage = () => {
  const { path } = useRouteMatch()

  const crumbs = [
    {
      label: 'My Coach',
      to: path
        .split('/')
        .slice(0, 2)
        .join('/'),
    },
  ]
  return (
    <>
      <PageHeader title="Lesson Packs" crumbs={crumbs} />
      <LessonPacksPageData />
    </>
  )
}

export default LessonPacksPage
