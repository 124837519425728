import React, { useEffect, useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import useStyles from './styles'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import { formatMoneyFromCents } from 'lib/utils/number'
import InfoTextContainer from 'components/info-text-container'

const CoachInsightsFacilityChart = ({ facilityData, title, options, selectedOption, showInfoText }) => {
  const classes = useStyles()
  const [selectedModel, setSelectedModel] = useState(selectedOption?.value || options[0]?.value)
  const [value, setValue] = useState(0)

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value)
  }

  const chartData = facilityData?.directPaymentToFacilityInCents?.map((monthData, index) => ({
    name: monthData.month,
    directPayments: facilityData.directPaymentToFacilityInCents[index].value / 100,
    additionalRevenue: facilityData.additionalRevenueToFacility[index][selectedModel] / 100,
  }))

  const getTotalRevenue = (data, model) => {
    const directPaymentsTotal = data?.directPaymentToFacilityInCents?.reduce((acc, curr) => acc + curr.value, 0)
    const additionalRevenueTotal = data?.additionalRevenueToFacility?.reduce((acc, curr) => acc + curr[model], 0)
    return directPaymentsTotal + additionalRevenueTotal
  }

  useEffect(() => {
    const totalRevenue = getTotalRevenue(facilityData, selectedModel)
    setValue(totalRevenue)
  }, [facilityData, selectedModel])

  return (
    <div className={classes.card}>
      <div className={classes.cardContent}>
        <p className={classes.description}>{title}</p>
        <p className={classes.value}>{formatMoneyFromCents(value)}</p>
        {
          options && options.length > 0
            ? <FormControl fullWidth className={classes.select}>
              <Select
                labelId="facility-chart-select-label"
                id="facility-chart-select"
                value={selectedModel}
                onChange={handleModelChange}
              >
                {
                  options.map((option) => (
                    <MenuItem value={option.value}>{option.label}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            : <div className={classes.selectedModelLabel}>
              {selectedOption?.label}
            </div>
        }
        <ResponsiveContainer width="100%" height={340}>
          <BarChart data={chartData} margin={{ left: -15 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" axisLine={false} tickLine={false} tick={{ fontSize: 12 }} />
            <YAxis
              axisLine={false}
              tickLine={false}
              tickFormatter={(value) => Number(value)}
              tick={{ fontSize: 12 }}
            />
            <Tooltip />
            <Bar dataKey="additionalRevenue" stackId="a" fill="#003366" name="Additional Revenue" />
            <Bar dataKey="directPayments" stackId="a" fill="#D3D3D3" name="Direct Payments" />
          </BarChart>
        </ResponsiveContainer>
        <div className={classes.legendContainer}>
          <div className={classes.legendItem}>
            <div className={classes.legendColor} style={{ backgroundColor: '#003366' }} />
            <p>Additional Revenue</p>
          </div>
          <div className={classes.legendItem}>
            <div className={classes.legendColor} style={{ backgroundColor: '#D3D3D3' }} />
            <p>Direct Payments</p>
          </div>
        </div>
        {
          showInfoText &&
          <InfoTextContainer style={{ marginTop: '1rem' }}>
            This is the information about what this specific model means and how it was calculated. <a href="/">Learn about models</a>
          </InfoTextContainer>
        }
      </div>
    </div>
  )
}

export default CoachInsightsFacilityChart
