import { DateTime } from 'luxon'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined'
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined'
import pluralize from 'lib/pluralize'
import { useTheme } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'

const LessonPackDetails = ({ classes, selectedLessonPack, timezone }) => {
  const theme = useTheme()
  const { quantity, expirationFromToday } = selectedLessonPack

  const formatExpirationDate = expFromToday => {
    return DateTime.fromISO(expFromToday, { zone: timezone }).toLocaleString(
      DateTime.DATE_FULL,
    )
  }

  return (
    <>
      <ListItem disableGutters={true}>
        <ListItemIcon>
          <ConfirmationNumberOutlinedIcon
            fontSize="small"
            className={classes.icons}
            color={theme.palette.secondary.dark}
          />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.attributes }}
          primary={`${quantity} ${pluralize(quantity, 'lesson')}`}
        />
      </ListItem>
      {expirationFromToday && (
        <>
          <ListItem disableGutters={true}>
            <ListItemIcon>
              <TimerOutlinedIcon
                fontSize="small"
                className={classes.icons}
                color={theme.palette.secondary.dark}
              />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.attributes }}
              primary={`Expires ${formatExpirationDate(expirationFromToday)}`}
            />
          </ListItem>
          <Typography variant="body1" className={classes.summaryDetails}>
            {`(Sessions must be booked by ${formatExpirationDate(
              expirationFromToday,
            )}.
            Remaining credits will be forfeited after this date.)`}
          </Typography>
        </>
      )}
    </>
  )
}

export default LessonPackDetails
