import React, { useState, useEffect } from 'react'
import {
  Typography,
  TextField,
  FormControl,
  Box,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import FacilitiesAlgolia from 'components/coaches-profile/profile-components/FacilitiesAlgolia'
import { GetFacilityQuery } from '../query'
import { useQuery } from '@apollo/client'

const GeneralInformation = ({
  formData,
  handleChange,
  errors,
}) => {
  const [facilityName, setFacilityName] = useState('')
  const [facilityId, setFacilityId] = useState('')
  const { data, refetch } = useQuery(GetFacilityQuery, {
    variables: {
      facilityId: facilityId,
    },
  })

  useEffect(() => {
    if (facilityId) {
      refetch({ facilityId: facilityId })
    }
  }, [facilityId, refetch])

  useEffect(() => {
    if (data?.facility) {
      handleChange({
        target: {
          name: 'facility',
          value: data?.facility?.id,
        },
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontSize: '34px', lineHeight: '42px', fontWeight: 500 }}
      >
        Yearly Coaching Metrics
      </Typography>

      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ mb: 3, fontSize: '16px', lineHeight: '24px', color: '#0000008A' }}
      >
        Enter the following information about the report you want to generate.
      </Typography>

      <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <TextField
          required
          label="Report Name"
          name="reportName"
          value={formData.reportName}
          onChange={handleChange}
          fullWidth
          error={!!errors?.reportName}
          helperText={errors?.reportName}
        />

        <FormControl required fullWidth error={!!errors?.year}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Year"
              views={['year']}
              maxDate={dayjs()}
              value={formData.year ? dayjs().year(parseInt(formData.year)) : null}
              onChange={(newValue) => {
                handleChange({
                  target: {
                    name: 'year',
                    value: newValue ? newValue.year().toString() : '',
                  },
                })
              }}
              slotProps={{
                textField: {
                  required: true,
                  error: !!errors?.year,
                  helperText: errors?.year,
                },
              }}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl required fullWidth error={!!errors?.facility}>
          <FacilitiesAlgolia
            name="facility"
            value={facilityName}
            onChange={(e) => {
              setFacilityName(e.target.value)
            }}
            onSelect={(facility) => {
              setFacilityId(facility.facility_id)
              setFacilityName(facility.facility_name)
            }}
            error={!!errors?.facility}
            helperText={errors?.facility}
            InputProps={{
              startAdornment: <SearchIcon sx={{ mr: 1 }} />,
            }}
          />
        </FormControl>
      </Box>
    </div>
  )
}

export default GeneralInformation
