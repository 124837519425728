import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 600,
    margin: '0 auto',
    padding: theme.spacing(3),
  },
  content: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  redeemIcon: {
    borderRadius: '50%',
    background: 'rgba(0,25,75,.08)',
    padding: '1rem',
    color: theme.palette.primary.main,
    width: '83px',
    height: '83px',
    boxSizing: 'border-box',
    margin: '12px auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fontSize: '40px',
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  acceptButton: {
    padding: '10px 14px',
    color: '#fff',
    '& svg': {
      marginRight: theme.spacing(1),
      '& polygon': {
        fill: '#fff',
      },
    },
  },
  homeButton: {
    marginBottom: theme.spacing(2),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}))
