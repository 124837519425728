import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  createReportContainer: {
    padding: theme.spacing(3),
    maxWidth: 1200,
    margin: '0 auto',
    '& .MuiStepIcon-root': {
      width: '10px',
      height: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      marginBottom: '4rem',
    },
  },
  mobileFooter: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '16px',
    backgroundColor: '#fff',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    gap: '8px',
  },
}))

export default useStyles
