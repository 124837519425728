import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import differenceInDays from 'date-fns/differenceInDays'
import parseISO from 'date-fns/parseISO'
import pluralize from 'lib/pluralize'
import PropTypes from 'prop-types'
import { useStyles } from './styles'
import RedeemIcon from '@material-ui/icons/Redeem'

const ChipsSection = ({
  status,
  requiresAccessCode,
  lessonCreditBalanceQuantity,
  isSingleSession,
  withLessonPacksCard,
  nextExpirationDate,
  giftingEnabled,
}) => {
  const classes = useStyles()
  const daysToExpire = differenceInDays(
    parseISO(nextExpirationDate),
    new Date(),
  )
  const showExpireWarning = daysToExpire < 30

  return (
    <Box className={classes.chipsSection}>
      {giftingEnabled && (
        <Chip
          icon={<RedeemIcon />}
          className={classes.filledChip}
          label="Giftable"
        />
      )}
      {status === 'INACTIVE' && (
        <Chip className={classes.chip} label="Private" />
      )}
      {requiresAccessCode && !lessonCreditBalanceQuantity && (
        <Chip className={classes.chip} label="Access Code" />
      )}
      {lessonCreditBalanceQuantity && isSingleSession ? (
        <>
          {showExpireWarning && (
            <Chip className={classes.chip} label="Expires Soon" />
          )}
          <Chip
            className={classes.chip}
            label={`${lessonCreditBalanceQuantity} ${pluralize(
              String(lessonCreditBalanceQuantity),
              'Session',
              'Sessions',
            )} left`}
          />
        </>
      ) : null}
    </Box>
  )
}

export default ChipsSection

ChipsSection.propTypes = {
  isSingleSession: PropTypes.bool,
  lessonCreditBalanceQuantity: PropTypes.number,
  nextExpirationDate: PropTypes.string,
  requiresAccessCode: PropTypes.bool.isRequired,
  status: PropTypes.oneOf(['ACTIVE', 'INACTIVE']),
  withLessonPacksCard: PropTypes.bool.isRequired,
  giftingEnabled: PropTypes.bool,
}
