import React, { useState } from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import Popover from '@material-ui/core/Popover'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import IconButton from '@material-ui/core/IconButton'
import AppsIcon from '@material-ui/icons/Apps'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ReactComponent as PGACoachIcon } from 'images/pga-coach-icon.svg'
import { ReactComponent as FGLogoIcon } from 'images/fg-logo-icon.svg'
import { ReactComponent as JRLLogoIcon } from 'images/jrl-logo-icon.svg'
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'
import { flags, useFeatureFlags } from 'lib/feature-flags'

const drawerWidth = 250

const useStyles = makeStyles(theme => ({
  brandSwitcherContainer: {
    display: 'flex',
    width: `${drawerWidth - 24 - 11}px`, // 24 for left padding, 11 for ?
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  listItemText: {
    '& > span': {
      fontWeight: 500,
    },
  },
}))

const BrandListItem = ({ logo, path, external, label }) => {
  const classes = useStyles()
  const { path: routeMatch } = useRouteMatch()
  const selected = path === routeMatch
  const linkProps = !external
    ? { component: NavLink, to: path }
    : { component: 'a', href: path, target: external.target || '_blank' }

  return (
    <ListItem button {...linkProps}>
      <ListItemIcon>{logo}</ListItemIcon>
      <ListItemText primary={label} className={classes.listItemText} />
      {selected && (
        <ListItemSecondaryAction>
          <CheckOutlinedIcon />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  )
}

const BrandSwitcher = ({ logo }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()
  const handleOpen = e => setAnchorEl(e.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const [isCoachBusinessInsightsEnabled] = useFeatureFlags([
    flags.FLAG_FEAT_COACH_BUSINESS_INSIGHTS,
  ])

  return (
    <>
      <div className={classes.brandSwitcherContainer}>
        <IconButton
          aria-controls="brand-switcher-menu"
          aria-haspopup="true"
          aria-label="expand"
          onClick={handleOpen}
        >
          <AppsIcon color="secondary" />
        </IconButton>
        <img
          src={logo.src}
          alt={logo.alt}
          style={{
            display: 'block',
            width:
              (isMobile ? logo.mobileWidth ?? logo.width : logo.width) ??
              '110px',
            height:
              (isMobile ? logo.mobileHeight ?? logo.height : logo.height) ??
              'auto',
          }}
        />
      </div>
      <Popover
        id="brand-switcher-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List style={{ width: drawerWidth, padding: 0 }}>
          <BrandListItem
            label="PGA Coach"
            path="/pga-coach"
            logo={<PGACoachIcon />}
          />
          <BrandListItem
            label="PGA Family Golf"
            path="/family-golf"
            logo={<FGLogoIcon />}
          />
          <BrandListItem
            label="PGA Jr. League"
            path="/junior-league"
            logo={<JRLLogoIcon />}
          />
          <BrandListItem
            label="PGA Coach Training"
            path="https://vt.lightspeedvt.com/share/redirect.cfm?signInURL=https://webservices.lightspeedvt.net/SAML/lsvt/login.aspx?h=auth0.pga.com&dest=https://vt.lightspeedvt.com/trainingCenter/"
            external="true"
            logo={<SchoolOutlinedIcon style={{ color: '#00234B' }} />}
          />
          <BrandListItem
            label="PGA Coach Business Insights"
            path={
              isCoachBusinessInsightsEnabled
                ? '/pga-coach/business-insights/reports'
                : 'https://sites.google.com/pgahq.com/pgacoachbusinessinsights/home'
            }
            external={isCoachBusinessInsightsEnabled ? undefined : 'true'}
            logo={<BarChartOutlinedIcon style={{ color: '#00234B' }} />}
          />
        </List>
      </Popover>
    </>
  )
}

export default BrandSwitcher
