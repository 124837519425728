import useStyles from './styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Button } from '@material-ui/core'

const CoachInsightsReportCard = ({ title, description, icon, sampleReport, onClick }) => {
  const classes = useStyles()
  return (
    <div onClick={onClick} className={classes.reportCardContainer}>
      <div className={classes.reportCardContentContainer}>
        <div className={classes.reportCardLogo}>
          {icon}
        </div>
        <div className={classes.reportCardContent}>
          <h4>{title}</h4>
          <p>
            {description}
          </p>
        </div>
      </div>
      <div className={classes.reportCardActions}>
        {
          sampleReport &&
          <Button variant="outlinedPrimary" className={classes.actionButton}>Sample Report</Button>
        }
        <ChevronRightIcon />
      </div>
    </div>
  )
}

export default CoachInsightsReportCard
