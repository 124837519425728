import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { MY_PGA_COM_HOST } from 'env'
import useIconAccessor from 'components/lesson-type-category/icons'
import SocialShareDialog from 'components/social-share-dialog'
import pluralize from 'lib/pluralize'
import clsx from 'clsx'
import { Avatar } from '@material-ui/core'
import LessonPackDetailsDialog from 'components/lesson-pack-details-dialog'
import { toFullTextDayDateWithTimezone } from 'utils/dateUtils'
import { durationText } from 'utils/durationUtils'
import RedeemIcon from '@mui/icons-material/Redeem'

const useStyles = makeStyles(theme => ({
  headerContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  headerRoot: {
    paddingBottom: '24px',
  },
  actionContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '12px',
  },
  avatar: {
    color: 'rgba(0, 35, 75, 1)',
    backgroundColor: 'rgba(244, 246, 248, 1)',
    width: '56px',
    height: '56px',
    marginRight: '4px',
  },
  text: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: '4px',
  },
  button: {
    paddingLeft: 0,
  },
  chip: {
    color: '#fff',
    borderRadius: '4px',
    height: '22px',
    fontSize: '10px',
    marginLeft: '10px',
    background: theme.palette.primary.main,
  },
  chipInfo: {
    background: theme.palette.blue.main,
  },
  chipAttention: {
    color: '#E31B0C',
    borderColor: '#E31B0C',
    borderStyle: 'solid',
    background: 'none',
  },
  chipGiftable: {
    background: '#fff',
    color: theme.palette.blue.main,
  },
  redeemIcon: {
    color: theme.palette.blue.main,
    marginRight: '4px',
    marginTop: '-2px',
  },
  chipGiftableText: {
    color: theme.palette.blue.main,
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
  },
}))

const formatBookingTypeUrl = lessonType => {
  if (!lessonType) {
    return null
  }

  const coachId = lessonType.coach.externalId
  const coachSlug = get(lessonType, 'coach.coachProfile.slug')
  const lessonTypeId = lessonType.id
  const locationId = get(lessonType, 'locations[0].id')

  return `${MY_PGA_COM_HOST}/coach/${coachSlug ||
    coachId}/schedule?lessonTypeId=${lessonTypeId}&locationId=${locationId}`
}

const composeTitle = (bt, isLessonPack, classes) => {
  return (
    <>
      {bt.giftingEnabled && (
        <Box
          display="flex"
          alignItems="center"
          mr={1}
          className={classes.chipGiftable}
        >
          <RedeemIcon className={classes.redeemIcon} />
          <Typography variant="caption" className={classes.chipGiftableText}>
            Giftable
          </Typography>
        </Box>
      )}
      {bt.accessCode && (
        <Chip
          label="Access Code"
          className={clsx(classes.chip, classes.chipAttention)}
        />
      )}
      {bt.lessonPacks.length > 0 ? (
        <Chip
          label="Lesson Pack"
          className={clsx(classes.chip, classes.chipInfo)}
        />
      ) : null}
      {isLessonPack && bt.remainingCredits > 0 && (
        <Chip
          label={`${bt.remainingCredits}/${bt.totalCredits} Remaining`}
          className={clsx(classes.chip, classes.chipInfo)}
        />
      )}
      {bt.status === 'INACTIVE' && (
        <Chip label="Private" color="secondary" className={classes.chip} />
      )}
    </>
  )
}

const BookingTypeCard = ({ bookingType, isLessonPack = false, refetch }) => {
  const classes = useStyles()
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const bookingTypeUrl = formatBookingTypeUrl(bookingType)
  const lessonPacksLength = bookingType.lessonPacks.length
  const accessor = useIconAccessor()

  return (
    <Card variant="outlined">
      <CardHeader
        disableTypography
        title={composeTitle(bookingType, isLessonPack, classes)}
        avatar={
          <Avatar variant="circular" className={classes.avatar}>
            {accessor[bookingType.category].icon}
          </Avatar>
        }
        titleTypographyProps={{ variant: 'subtitle1' }}
        classes={{
          content: classes.headerContent,
          root: classes.headerRoot,
        }}
      />
      <CardContent>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{ height: '20px' }}
        >
          <Typography
            variant="h6"
            style={{ marginBottom: '8px', lineHeight: '24px' }}
          >
            {bookingType.title}
          </Typography>

          {lessonPacksLength === 0 ? (
            <>
              <Box display="flex">
                <Typography variant="caption" className={classes.text}>
                  {bookingType.locations[0].name}
                </Typography>
              </Box>
              <Box display="flex">
                <Typography variant="caption" className={classes.text}>
                  {durationText(bookingType.durationInMinutes)}
                </Typography>
              </Box>
              {isLessonPack && (
                <Box display="flex">
                  <Typography variant="caption" className={classes.text}>
                    {bookingType.expiresAt
                      ? `Expires ${toFullTextDayDateWithTimezone(
                        bookingType.expiresAt,
                      )}`
                      : 'Does not expire'}
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            <Box display="flex" flexDirection="column">
              <Typography
                gutterBottom
                variant="caption"
                className={classes.text}
              >
                {bookingType.locations[0].name}
              </Typography>
              <Typography variant="caption" className={classes.text}>
                {bookingType.lessonPacks.map((lessonPack, i) => (
                  <>
                    {lessonPacksLength === i + 1 && lessonPacksLength !== 1
                      ? ' and '
                      : null}
                    {i !== 0 && lessonPacksLength !== i + 1 ? ', ' : null}
                    {lessonPack.quantity}
                  </>
                ))}
                {pluralize(lessonPacksLength, ' pack')};
                <span>
                  {` ${durationText(bookingType.durationInMinutes)} `}/ lesson
                </span>
              </Typography>
            </Box>
          )}
        </Box>
      </CardContent>
      <CardActions
        className={classes.actions}
        classes={{
          root: classes.actionContent,
        }}
      >
        {bookingTypeUrl && bookingType.accessCode && (
          <SocialShareDialog
            trigger={
              <IconButton>
                <LockOutlinedIcon color="primary" />
              </IconButton>
            }
            dialogTitle="Share"
            shareTitle={bookingType.title}
            shareUrl={bookingTypeUrl}
            bookingType={bookingType}
            att="pga-lesson-type"
          />
        )}
        <IconButton
          data-cy={bookingType.title + ' edit'}
          onClick={() =>
            isLessonPack
              ? setIsOpen(!isOpen)
              : history.push(
                `/pga-coach/bookings/booking-types/edit/${bookingType.id}`,
              )
          }
        >
          <EditOutlinedIcon color="primary" />
        </IconButton>
        <LessonPackDetailsDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          booking={bookingType}
          refetch={refetch}
        />
        {bookingTypeUrl && (
          <SocialShareDialog
            trigger={
              <IconButton>
                <ShareOutlinedIcon color="primary" />
              </IconButton>
            }
            dialogTitle="Share"
            shareTitle={bookingType.title}
            shareUrl={bookingTypeUrl}
            bookingType={bookingType}
            att="pga-lesson-type"
          />
        )}
      </CardActions>
    </Card>
  )
}

export default BookingTypeCard
