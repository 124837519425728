import React from 'react'
import { useStyles } from './styles'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import Link from '@material-ui/core/Link'

const AlertWithDivider = ({
  text,
  icon,
  ctaLink,
  variant,
  ctaLinkText = 'LEARN MORE',
}) => {
  const classes = useStyles({ variant })

  return (
    <Alert icon={icon} severity="info" className={classes.warningBox}>
      <Box
        display="flex"
        height="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Divider orientation="vertical" className={classes.verticalDivider} />
        <Box flex="1" ml={2}>
          <Typography variant="body2">{text}</Typography>
        </Box>
        {ctaLink && (
          <Box flex="0 0 auto" ml={2}>
            <Link
              href={ctaLink}
              style={{
                textAlign: 'center',
                textDecoration: 'none',
                border: '1px solid #00234B',
                borderRadius: '4px',
                padding: '4px 8px',
                display: 'block',
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {ctaLinkText}
            </Link>
          </Box>
        )}
      </Box>
    </Alert>
  )
}

export default AlertWithDivider
