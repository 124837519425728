import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  card: {
    border: '1px solid #E0E0E0',
    padding: '1.5rem',
    textAlign: 'left',
    borderRadius: '12px',
    fontFamily: 'Roboto',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: 0,
  },
  avatar: {
    backgroundColor: '#EBEFF5',
    width: '68px',
    height: '68px',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '48px',
      height: '48px',
    },
  },
  icon: {
    color: '#00234B',
    width: '36px !important',
    height: '36px !important',
    fontSize: '36px !important',
    [theme.breakpoints.down('sm')]: {
      width: '24px !important',
      height: '24px !important',
      fontSize: '24px !important',
    },
  },
  value: {
    fontSize: '30px',
    marginTop: 0,
    fontWeight: 500,
    marginBottom: 0,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },
  description: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#0000008A',
    marginBottom: 0,
    marginTop: '0',
    lineHeight: '24px',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 500,
      marginTop: '0',
    },
  },
  valueContainer: {
    marginTop: '12px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '12px',
    },
  },
}))

export default useStyles
